import { useState } from "react"
import { AiTwotoneHome } from "react-icons/ai"
import { FaGem, FaFootballBall, FaUsers } from "react-icons/fa"
import { FiMoreHorizontal } from "react-icons/fi"
import { MdSettings } from "react-icons/md"
import { VscGraphLine } from "react-icons/vsc";
import { useLocation, useNavigate } from "react-router-dom"
import styled from "styled-components"
import { Box, Link, Stack, Spacer, Text, VStack, Wrap, WrapItem } from "@chakra-ui/react"
import { NAV_OPTIONS, NavOptionsDict } from "../../../constants"
import { useUserContext } from "../../../hooks" 
import { NavOption } from "../../../types"

const NotificationBadge = styled(Box)``
NotificationBadge.defaultProps = {
  position: "absolute",
  top: -0.5,
  left: "80%"
}

interface Props {
  navOption: NavOption,
  isCurrentNavOption: boolean
}

const NavigationIcon = ({ navOption, isCurrentNavOption }: Props) => {
  const navigate = useNavigate()
  const { user } = useUserContext()
  const color = isCurrentNavOption ? "#FBA426" : "grey"
  const backgroundColor = isCurrentNavOption ? "brand.300" : "transparent"

  const getCleanNavigationText = (str: string) => {
    if (str === "players") return "All Prospects"
    if (str === "stats") return "Your Stats"
    if (str === "profile") return "Your Players"
    const cleansedStr = str.replace("_", " ")
    let splitStr = cleansedStr.toLowerCase().split(' ')
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
    }
    // Directly return the joined string
    return splitStr.join(' ')
  }

  const getIconByNavOption = () => {
    switch (navOption) {
      case NavOption.HOME:
        return (
          <AiTwotoneHome color={color} size={30} />
        )
      case NavOption.PROFILE:
        return (
          <FaFootballBall color={color} size={30} />
        )
      case NavOption.PLAYERS:
        return (
          <FaUsers color={color} size={30} />
        )
      case NavOption.INVITATIONAL:
        return (
          <FaGem color={color} size={30} />
        )
      case NavOption.SETTINGS:
        return (
          <MdSettings color={color} size={30} />
        )
      case NavOption.STATS:
        return (
          <VscGraphLine color={color} size={30} />
        )
      default:
        return null
    }
  }

  const handleIconClick = () => {
    if (navOption === NavOption.PROFILE ) {
      if (user && user.username) {
        navigate(`/profile/${user.username}`)
      } else {
        navigate(`/demo`)
      }
    } else {
      navigate(`/${NavOptionsDict[navOption]}`)
    }
  }

  return (
    <VStack
      w="100%"
      p={5}
      borderRadius={10}
      background={backgroundColor}
      cursor="pointer"
      onClick={() => handleIconClick()}
    >
      { getIconByNavOption() }
      <Text color={color} fontSize="2xs" textAlign="center" fontWeight="extrabold">{ getCleanNavigationText(navOption) }</Text>
    </VStack>
  )
}

interface BottomSectionLinkProps {
  text: string
  url: string
}

const BottomSectionLink = ({ text, url }: BottomSectionLinkProps) => {
  const navigate = useNavigate()
  return (
    <WrapItem>
      <Text as={Link} color="grey.400" fontSize={10} fontWeight="bold" onClick={() => navigate(`/${url}`)}>
        { text }
      </Text>
    </WrapItem>
  )
}

export const Navigation = () => {
  const location = useLocation()
  const { user } = useUserContext()
  const bottomLinks = [{ text: "About", url: "about" }, { text: "Privacy Policy", url: "privacy" }, { text: "Terms", url: "terms" }, { text: "Contact Us", url: "contact-us" }]
  
  const [isBottomVisible, setIsBottomVisible] = useState(false)

  const getIsCurrentNavOption = (navOption: NavOption) => {
    if (location.pathname === "/") return `/${NavOptionsDict[navOption]}`.includes("players")
    if (navOption === NavOption.PROFILE && user && user.username) return location.pathname === `/profile/${user.username}`
    if (navOption === NavOption.PROFILE && !user) return location.pathname === "/demo"
    return location.pathname === `/${NavOptionsDict[navOption]}`
  }

  return (
    <VStack h="100%">
      <Box h="25%" w="100%" p={5} />
      <Spacer />
      <VStack m={10}>
        {
          NAV_OPTIONS.map(navOption => {
            const isCurrentNavOption = getIsCurrentNavOption(navOption)
            return (
              <NavigationIcon key={navOption} navOption={navOption} isCurrentNavOption={isCurrentNavOption} />
            )
          })
        }
      </VStack>
      <Spacer />
      <Stack h="25%" w="100%">
        <Spacer />
        {
          isBottomVisible ? (
            <VStack>
              <FiMoreHorizontal color="grey" cursor="pointer" onClick={() => setIsBottomVisible(false)} />
              <Wrap pl={5} pr={5}>
                {
                  bottomLinks.map(link => {
                    return (
                      <BottomSectionLink key={link.url} text={link.text} url={link.url}/>
                    )
                  })
                }
              </Wrap>
              <Text color="grey.400" fontSize={10} fontWeight="bold" p={5}>
                © 2024 Armchair GMs LLC.
              </Text>
            </VStack>
          ) : (
            <VStack cursor="pointer" onClick={() => setIsBottomVisible(true)}>
              <FiMoreHorizontal color="grey" />
              <Text fontSize="xx-small" color="grey" pb={2}>
                Show More
              </Text>
            </VStack>
          )
        }
      </Stack>
    </VStack>
  )
}