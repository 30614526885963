import { useState } from "react"
import { FiEdit3, FiPlus } from "react-icons/fi"
import { Box, HStack, Icon, Image, Spacer, Stack, Text, useColorModeValue, VStack } from "@chakra-ui/react"
import { UserPlayerSelection } from "../../../types"
import { useDeviceContext } from "../../../hooks"

interface Props {
  index: number
  isLocked: boolean
  width: string
  selection: UserPlayerSelection
  handlePlayerCardClick: (index: number) => void
}

export const SelectPlayerItem = ({ index, isLocked, selection, width, handlePlayerCardClick }: Props) => {
  const { screenHeight } = useDeviceContext()
  const [isHovered, setIsHovered] = useState<boolean>(false)

  const plusColor = useColorModeValue("#2E3C49", "white")

  const topBarBg = index === 0 ? "linear-gradient(to right, #705E2B, #F2E085)" : index === 1 || index === 2 ? "linear-gradient(to right, #00e7ff, #ff00e7)" : "linear-gradient(to right, #54a29e, #e7d7ba)"
  const mainBg = index === 0 ? "linear-gradient(to bottom, rgba(114,96,44, 0.2), rgba(114,96,44, 0))" : index === 1 || index === 2 ? "linear-gradient(to bottom, rgba(26,228,255, 0.2), rgba(26,228,255, 0))" : "linear-gradient(to bottom, rgba(86,163,157, 0.2), rgba(86,163,157, 0))"

  const getBodyView = () => {
    if (selection?.nflDraftSelection) {
      return (
        <Stack
          h="100%"
          w="100%"
          border="1px solid #2E3C49"
          bgImage={`url(https://static.wikia.nocookie.net/valorant/images/2/28/Champions_LA_2023_Card_Large.png)`}
          backgroundSize="cover"
          backgroundPosition="center"
        >
          <Stack
            width="100%"
            height="100%"
            bg="rgba(0, 0, 0, 0.8)"
            p={0}
            m={0}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <Text fontSize="2xl" fontWeight="bold" p={5} color="white">
              { `${selection.nflDraftSelection.player.firstName} ${selection.nflDraftSelection.player.lastName}` }
            </Text>
            <Text fontSize="lg" fontWeight="bold" pl={5} pr={5} color="white">
              { selection.nflDraftSelection.player.school.name }
            </Text>
            <Text color="brand.500" fontWeight="bold" fontSize="lg" pl={5} pr={5}>
              { selection.nflDraftSelection.player.position.name }
            </Text>
            <Spacer />
            {
              isHovered && !isLocked && (
                <HStack>
                  <Spacer />
                  <VStack>
                    <Icon as={FiEdit3} h={10} w={10} color="white" />
                    <Text fontSize="lg" color="white">
                      Change Player
                    </Text>
                  </VStack>
                  <Spacer />
                </HStack>
              )
            }
            <Spacer />
            <HStack p={5}>
              <Image src={selection.nflDraftSelection.team.picture} h={10} />
              <Spacer />
              <Text fontSize="3xl" fontWeight="bold" color="white">
                {`#${selection.nflDraftSelection.pickNumber}` }
              </Text>
            </HStack>
          </Stack>
        </Stack>
      )
    } else {
      return (
        <VStack h="100%" w="100%" border="1px solid #2E3C49" _hover={{ bg: mainBg }}>
          <Spacer />
          <VStack
            width="70px"
            height="70px"
            borderLeft="2px solid #F8A426"
            borderRight="2px solid #F8A426"
            borderTop="1px solid #2E3C49"
            borderBottom="1px solid #2E3C49"
            transform="rotate(45deg)"
          >
            <Spacer />
            <Icon transform="rotate(45deg)" as={FiPlus} h={10} w={10} color={plusColor} />
            <Spacer />
          </VStack>
          <Spacer />
        </VStack>
      )
    }
  }

  return (
    <Box w={width} h={screenHeight * 0.7} borderRadius={5} pb={10} cursor="pointer" onClick={() => handlePlayerCardClick(index)}>
      <Box w="100%" h={5} bg={topBarBg} borderTopRadius={5} />
      { getBodyView() }
    </Box>
  )
}