import { useNavigate } from "react-router-dom"
import { Flex, Text, Spacer } from "@chakra-ui/react"

export const Navigation = () => {
  const navigate = useNavigate()

  return (
    <Flex
      h="10vh"
      alignItems="center"
      p="6"
      position="sticky"
      top="0"
      zIndex="sticky"
      w="full"
      bg="#0D1017"
    >
      <Text fontSize="xl" fontWeight="bold" color="white" onClick={() => navigate("/")} cursor="pointer">
        Armchair GMs
      </Text>
      <Spacer />
    </Flex>
  )
}