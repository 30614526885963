import { Spacer, Text } from "@chakra-ui/react"
import { Section } from "../../ui"

interface Props {
  rank: number | null
  title: string
}

export const SummaryBox = ({ title, rank }: Props) => {
  const getGradeByValue = (rank: number) => {
    let grade = ""
    if (rank < 1) {
      grade = "A+"
    } else if (rank < 2) {
      grade = "A"
    } else if (rank < 3) {
      grade = "B"
    } else if (rank < 4) {
      grade = "C"
    } else {
      grade = "D"
    }

    return grade
  }

  return (
    <Section p={5} mb={5} w="30%">
      <Text fontSize="xs" fontWeight="bold">
        { title }
      </Text>
      <Spacer />
      <Text fontSize="6xl" fontWeight="bold">
        { rank ? getGradeByValue(rank) : "N/A" }
      </Text>
    </Section>
  )
}