import { HStack, Spacer, Text } from "@chakra-ui/react"
import { Section } from "../../ui"

interface Props {
  rank1: number | null
  rank2: number | null
  title: string
}

export const ComparisonBox = ({ rank1, rank2, title }: Props) => {
  const calculateDifferenceGrade = (valueA: number, valueB: number) => {
    const difference = valueA - valueB
    if (difference < -2) {
      return "A+"
    } else if (difference < -1) {
      return "A"
    } else if (difference < 0) {
      return "B"
    } else if (difference < 1) {
      return "C+"
    } else if (difference < 2) {
      return "C"
    } else if (difference < 3) {
      return "C-"
    } else {
      return "D"
    }
  }

  const getSmallText = (valueA: number, valueB: number) => {
    const difference = valueA - valueB
    if (difference < -2) {
      return "Top 1%"
    } else if (difference < -1) {
      return "Significantly Better"
    } else if (difference < 0) {
      return "Slightly Better"
    } else if (difference < 1) {
      return "On Par"
    } else if (difference < 2) {
      return "Top 60%"
    } else if (difference < 3) {
      return "Top 75%"
    } else {
      return "Top 90%"
    }
  }

  return (
    <Section p={5} mb={5} w="30%">
      <Text fontSize="xs" fontWeight="bold">
        { title }
      </Text>
      <Spacer />
      <HStack>
        <Text fontSize="6xl" fontWeight="bold">
          { rank1 && rank2 ? calculateDifferenceGrade(rank1, rank2) : "N/A"}
        </Text>
        <Spacer />
        {
          (rank1 && rank2) && (
            <Text fontSize="2xs" fontWeight="bold" pt={5}>
              { getSmallText(rank1, rank2) }
            </Text>
          )
        }
      </HStack>
    </Section>
  )
}