import { useNavigate } from "react-router-dom"
import { HStack, Link, Stack, Text } from "@chakra-ui/react"
import { Section } from "../../ui"
import { UserPlayerRating } from "../../../types"

interface Props {
  userPlayerRatings: UserPlayerRating[]
}

const RecentPlayerRatingItem = ({ userPlayerRating }: { userPlayerRating: UserPlayerRating }) => {
  const navigate = useNavigate()

  const handleUsernameClick = (e: React.MouseEvent<Element, MouseEvent>) => {
    navigate(`/profile/${userPlayerRating.username}`)
    e.stopPropagation()
  }

  return (
    <Section cursor="pointer" p={5} mb={5} onClick={() => navigate(`/player/${userPlayerRating.player.id}`)}>
      <HStack pl={2} pr={2}>
        <Stack>
          <Text
            fontSize="2xl"
            fontWeight="bold"
          >
            { `${userPlayerRating.player.firstName} ${userPlayerRating.player.lastName}` }
          </Text>
          <HStack>
            <Text
              color="#8b8b9b"
              fontSize="md"
              fontWeight="bold"
            >
              { `${userPlayerRating.player.school.name} - ` }
            </Text>
            <Text color="brand.500" fontWeight="bold" fontSize="md">
              { userPlayerRating.player.position.name }
            </Text>
          </HStack>
          <Stack spacing={0} pt={5}>
            <Link fontSize={14} fontWeight="bold" onClick={(event) => handleUsernameClick(event)}>
              { `${userPlayerRating.username}'s Rating:` }
            </Link>
            <Text
              fontSize="sm"
              fontWeight="bold"
            >
              { userPlayerRating.label }
            </Text>
          </Stack>
        </Stack>
      </HStack>
    </Section>
  )
}

export const RecentPlayerRatingsList = ({ userPlayerRatings }: Props) => {
  return (
    <Stack w="100%" p={10}>
      {
        userPlayerRatings.map((userPlayerRating, index) => {
          return (
            <RecentPlayerRatingItem key={index} userPlayerRating={userPlayerRating} />
          )
        })
      }
    </Stack>
  )
}
