import { useEffect, useState } from "react"
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Select,
  VStack,
  useColorModeValue
} from "@chakra-ui/react"
import { NflDraftSelection } from "../../../types"
import { PlayerListItem } from "./PlayerListItem"

interface Props {
  isOpen: boolean
  rounds: number[]
  nflDraftSelections: NflDraftSelection[]
  selectionIndex: number
  startingRound: number
  filterNflDraftSelectionsByRound: (round: number) => void
  selectPlayer: (index: number, nflDraftSelection: NflDraftSelection) => void
  onClose: () => void
}

export const SelectPlayerModal = ({ isOpen, rounds, nflDraftSelections, selectionIndex, startingRound, filterNflDraftSelectionsByRound, selectPlayer, onClose }: Props) => {
  const bg = useColorModeValue("light.background", "dark.background")
  const [currentRound, setCurrentRound] = useState<number>(startingRound)

  useEffect(() => {
    filterNflDraftSelectionsByRound(currentRound)
  }, [currentRound, filterNflDraftSelectionsByRound])

  const handleCurrentRoundChange = (roundStr: string) => {
    const round = parseInt(roundStr)
    setCurrentRound(round)
  }

  return (
    <Modal isOpen={isOpen} size="full" onClose={onClose}>
      <ModalOverlay/>
      <ModalContent bg={bg}>
        <ModalHeader color="white"></ModalHeader>
        <ModalCloseButton color="grey" />
        <ModalBody h="100vh">
          <Select
            variant="filled"
            w="20%"
            bg="white"
            color="brand.50"
            fontWeight="bold"
            mb={10}
            onChange={(event) => handleCurrentRoundChange(event.target.value)}
          >
            {
              rounds.map((round, index) => {
                return (
                  <option key={index} value={round}>{ round === 8 ? `Undrafted` : `Round ${round}` }</option>
                )
              })
            }
          </Select>
          <VStack w="100%" h="100vh" p={5} overflowY="scroll">
            {
              nflDraftSelections.map((selection, index) => {
                return (
                  <PlayerListItem
                    key={index}
                    index={selectionIndex}
                    nflDraftSelection={selection}
                    player={selection.player}
                    pickNumber={selection.pickNumber}
                    team={selection.team}
                    selectPlayer={selectPlayer}
                  />
                )
              })
            }
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}