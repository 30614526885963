import { HStack, Link, Stack, Text } from "@chakra-ui/react"
import { useDeviceContext } from "../../../hooks"

export const PasswordSection = () => {
  const { isMobile } = useDeviceContext()
  
  return (
    <>
      {
        isMobile ? (
          <Stack p={5}>
            <Text fontWeight="bold" pr={5}>
              Password
            </Text>
            <Text as={Link} textDecoration="underline">
              Click here to change your password.
            </Text>
          </Stack>
        ) : (
          <HStack p={5}>
            <Text fontWeight="bold" pr={5}>
              Password
            </Text>
            <Text as={Link} textDecoration="underline">
              Click here to change your password.
            </Text>
          </HStack>
        )
      }
    </>
  )
}