import { HStack, Spacer, Stack, Text, useColorModeValue, VStack } from "@chakra-ui/react"
import { UserStats } from "../../../types"
import { getRatingBottomText, getRatingTopText } from "../../../utils"

interface Props {
  index: number
  isEnd: boolean
  stats: UserStats
  handleOpenDetailedStats: (stats: UserStats) => void
}

const PlayerStats = ({ label, value } : { label: string, value: string }) => {
  const bg = useColorModeValue("light.400", "dark.400")
  return (
    <VStack borderRadius={10} p={5} bg={bg}>
      <Text fontSize="2xs" fontWeight="bold">
        { label }
      </Text>
      <Text fontSize="lg" fontWeight="bold">
        { value }
      </Text>
    </VStack>
  )
}

export const PlayerStatsItem = ({ index, isEnd, stats, handleOpenDetailedStats }: Props) => {
  const bg = useColorModeValue("light.100", "dark.100")
  const bg2 = useColorModeValue("light.200", "dark.200")

  const handleViewMoreStatsClick = (e: React.MouseEvent<Element, MouseEvent>) => {
    handleOpenDetailedStats(stats)
    e.stopPropagation()
  }

  const getGradeByValue = (rank: number) => {
    if (rank < 1) {
      return "A+"
    } else if (rank < 2) {
      return "A"
    } else if (rank < 3) {
      return "B"
    } else if (rank < 4) {
      return "C"
    } else if (rank < 100) {
      return "D"
    }

    return "N/A"
  }

  return (
    <Stack
      borderTopRadius={index === 0 ? 10 : 0}
      borderBottomRadius={isEnd ? 10 : 0}
      cursor="pointer"
      p={5}
      bg={ index % 2 === 0 ? bg : bg2}
      onClick={handleViewMoreStatsClick}
    >
      <HStack pl={2} pr={2}>
        <Stack>
          <Text fontSize="2xl" fontWeight="bold">
            { `${stats.player.firstName} ${stats.player.lastName}` }
          </Text>
          <HStack>
            <Text
              color="grey.400"
              fontSize="md"
              fontWeight="bold"
            >
              { `${stats.player.school.alias ? stats.player.school.alias : stats.player.school.name } - ` }
            </Text>
            <Text fontWeight="bold" color="brand.500" fontSize="md">
              { stats.player.position.name }
            </Text>
          </HStack>
        </Stack>
        <Spacer />
        <HStack spacing={5}>
          <PlayerStats label="Your Projection" value={`${getRatingTopText(stats.rating)} ${getRatingBottomText(stats.rating)}`} />
          <PlayerStats label="How they performed" value={`${stats.performanceRank ?? "N/A"}`} />
          <PlayerStats label="Your Grade" value={stats.userDelta ? getGradeByValue(stats.userDelta) : "N/A"} />
        </HStack>
      </HStack>
    </Stack>
  )
}