import axios from "axios"
import { FaFire, FaRegCircle, FaSnowflake } from "react-icons/fa6"
import {
  Box,
  HStack,
  Spacer,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react"
import { BoomBustBox } from "./"
import { GradientButton } from "../../ui"
import { useDeviceContext, useUserContext } from "../../../hooks"
import { PlayerWithDetails, Rating, UserPlayerValueType } from "../../../types"

interface Props {
  playerWithDetails: PlayerWithDetails
  playerRating: Rating | null
  userPlayerValue: string | null
  setIsSetPlayerRatingOpen: (isOpen: boolean) => void
  setUserPlayerValue: (userPlayerValue: UserPlayerValueType) => void
}

export const PlayerRatingSection = ({ playerWithDetails, playerRating, userPlayerValue, setIsSetPlayerRatingOpen, setUserPlayerValue }: Props) => {
  const { isMobile } = useDeviceContext()
  const toast = useToast()
  const { user } = useUserContext()

  const showSignInToast = () => {
    toast({
      title: "Sign In",
      description: "You must sign in to enter your rating",
      status: "error",
      duration: 5000,
      isClosable: true,
    })
  }

  const handleEnterYourRatingClick = () => {
    if (!user) {
      showSignInToast()
      return
    }
    setIsSetPlayerRatingOpen(true)
  }

  const getToastInfo = (value: string) => {
    if (value === UserPlayerValueType.BOOM) {
      return {
        title: "BOOM",
        status: "success",
        icon: <FaFire size={25} />
      }
    } else if (value === UserPlayerValueType.BUST) {
      return {
        title: "BRRRR",
        status: "info",
        icon: <FaSnowflake size={25} />
      }
    } else {
      return {
        title: "OK",
        status: "warning",
        icon: <FaRegCircle size={25} />
      }
    }
  }

  const handleBoomBustClick = async (userPlayerValue: UserPlayerValueType) => {
    if (!user) {
      showSignInToast()
      return
    }
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/user-player/upsert`, {
        playerId: playerWithDetails.player.id,
        nflDraftId: playerWithDetails.player.nflDraft!.id,
        value: userPlayerValue
      }, { withCredentials: true })

      const { data } = res
      if (data.success) {
        setUserPlayerValue(userPlayerValue)
        const { title, status, icon } = getToastInfo(userPlayerValue)
        toast({
          title,
          status: status as any,
          duration: 5000,
          isClosable: true,
          icon,
        })
      }
    } catch(e) {
      console.log(e)
      toast({
        title: "Oops something went wrong",
        description: "Please try again later",
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    }
  }

  return (
    <VStack w={ isMobile ? "100%" : "50%" } h="100%" p={10} spacing="5">
      <Spacer />
      <Text fontSize="xs" fontWeight="bold" p={5}>
        How do you feel about { playerWithDetails.player.firstName } { playerWithDetails.player.lastName }?
      </Text>
      <HStack spacing={5}>
        <BoomBustBox
          userPlayerValue={UserPlayerValueType.BOOM}
          isFilled={userPlayerValue === UserPlayerValueType.BOOM}
          isLocked={false}
          handleBoomBustClick={handleBoomBustClick}
        />
        <BoomBustBox
          userPlayerValue={UserPlayerValueType.BUST}
          isFilled={userPlayerValue === UserPlayerValueType.BUST}
          isLocked={false}
          handleBoomBustClick={handleBoomBustClick}
        />
      </HStack>
      <BoomBustBox
        userPlayerValue={UserPlayerValueType.OK}
        isFilled={userPlayerValue === UserPlayerValueType.OK}
        isLocked={false}
        handleBoomBustClick={handleBoomBustClick}
      />
      {
        playerRating  ? (
          <GradientButton onClick={handleEnterYourRatingClick} p={7} w="70%" mt={5}>
            <Box>
              <span style={{ fontSize: "9px" }}>Your Big Board Rating:</span>
              <br />
              { playerRating.label }
            </Box>
          </GradientButton>
        ) : (
          <GradientButton onClick={handleEnterYourRatingClick} mt={5}>Add to your Big Board</GradientButton>
        )
      }
      <Spacer />
    </VStack> 
  )
}