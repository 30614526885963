import {
    Box,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    ModalFooter,
    Text,
} from "@chakra-ui/react"

interface Props {
    isOpen: boolean
    text: string
    onClose: () => void
}

export const HowItWorksModal = ({ isOpen, text, onClose }: Props) => {
    return (
        <Modal isOpen={isOpen} size="3xl" onClose={onClose}>
            <ModalOverlay/>
            <ModalContent bg="#323238">
                <ModalHeader color="white">How It Works</ModalHeader>
                <ModalCloseButton color="grey" />
                <ModalBody>
                    <Box w="100%" p={5}>
                        <Text color="white">
                            { text }
                        </Text>
                    </Box>
                </ModalBody>
                <ModalFooter>
                    <Button variant="ghost" bgGradient="linear-gradient(to right, #EB46A6, #E20FF5)" color="white" mr={3} onClick={onClose}>
                        Done
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}