import { RiCheckboxCircleFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom"
import { HStack, Spacer, Stack, Text, useColorModeValue } from "@chakra-ui/react"
import { useDevice } from "../../hooks"
import { Player } from "../../types"
import { Section } from "."

interface Props {
  player: Player
  hideFilmsBox?: boolean
  isChecked?: boolean
}

export const PlayerItem = ({ player, hideFilmsBox, isChecked }: Props) => {
  const { isMobile } = useDevice()
  const navigate = useNavigate()
  const bgContainer = useColorModeValue("#EEF2F8", "dark.200")

  return (
    <Section cursor="pointer" p={5} mb={5} onClick={() => navigate(`/player/${player.id}`)}>
      <HStack pl={2} pr={2}>
        <Stack>
          <Text fontSize="2xl" fontWeight="bold" pl={isMobile ? 0 : 2}>
            { `${player.firstName} ${player.lastName}` }
          </Text>
          <HStack pl={isMobile ? 0 : 2}>
            <Text
              color="grey.400"
              fontSize="md"
              fontWeight="bold"
            >
              { `${player.school.alias ? player.school.alias : player.school.name } - ` }
            </Text>
            <Text fontWeight="bold" color="brand.500" fontSize="md">
              { player.position.name }
            </Text>
          </HStack>
          {
            !hideFilmsBox && (
              <Text
                w={ isMobile ? 160 : 200 }
                mt={3}
                p={3}
                bg={bgContainer}
                borderRadius={10}
                fontWeight="bold"
                fontSize={ isMobile ? "xs" : "md" }
                color="brand.500"
              >
                { `Film Room: ${ player.school.film.length } Videos` }
              </Text>
            )
          }
        </Stack>
        <Spacer />
        {
          isChecked && (
            <RiCheckboxCircleFill size={30} color="#FBA426" />
          )
        }
      </HStack>
    </Section>
  )
}