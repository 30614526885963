import {
  Box,
  HStack,
  Modal,
  ModalCloseButton,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Stack,
  Text,
  useColorModeValue,
  VStack,
  Spacer,
} from "@chakra-ui/react"
import { Player, Rating } from "../../../types"
import { Section } from "../../ui"
import { RATINGS } from "../../../utils"

interface Props {
  isOpen: boolean
  player: Player
  onClose: () => void
  handleRatingClick: (rating: Rating) => void
}

const RatingItem = ({ rating, index, handleRatingClick }: { rating: Rating, index: number, handleRatingClick: (rating: Rating) => void }) => {
  return (
    <Section key={index} p={5} cursor="pointer" onClick={() => handleRatingClick(rating)}>
      <HStack w="100%">
        <VStack w={20} h={20} bg="brand.500" spacing={0} borderRadius={5}>
          <Spacer />
          <Text fontSize="xs" fontWeight="bold" color="black">
            Round
          </Text>
          <Text fontSize="lg" fontWeight="bold" color="black">
            { rating.round !== 8 ? rating.round : "UD" }
          </Text>
          <Spacer />
        </VStack>
        <Stack>
          <Text fontSize="lg" fontWeight="bold" pl={5} pr={5}>
            { rating.label }
          </Text>
          <Text fontSize="sm" fontWeight="bold" pl={5} pr={5}>
            { `Picks: ${rating.userRankMin} - ${rating.userRankMax}` }
          </Text>
        </Stack>
      </HStack>
    </Section>
  )
}

export const SetPlayerRatingModal = ({ isOpen, player, onClose, handleRatingClick }: Props) => {
  const bg = useColorModeValue('light.background', 'dark.background')

  return (
    <Modal isOpen={isOpen} size="4xl" onClose={onClose}>
      <ModalOverlay />
      <ModalContent h="2xl" bg={bg}>
        <ModalBody p={0} h="100%">
          <HStack h="100%">
            <Box
              position="relative"
              borderRadius={5}
              backgroundSize="cover"
              backgroundPosition="center"
              w="30%"
              h="100%"
              bgImage={`url("https://armchairgms-images.s3.amazonaws.com/superbow-bg-1.png")`}
            >
              <Box
                position="absolute"
                top="0"
                left="0"
                w="100%"
                h="100%"
                bg="rgba(130, 130, 130, 0.5)"
                borderRadius={5}
                style={{ backdropFilter: 'blur(30px)' }}
              />
            </Box>
            <Box w="70%" h="100%" p={5}>
              <HStack>
                <Text fontSize="xl" fontWeight="bold" pt={5}>
                  Where would you draft { `${player.firstName} ${player.lastName}` }?
                </Text>
                <ModalCloseButton />
              </HStack>
              <Text fontSize="sm" fontWeight="bold" pb={10}>
                (Not where you think they will get drafted)
              </Text>
              <Box
                overflowY="auto"
                maxH="80%"
                mb={5}
              >
                <Stack overflowY="auto" spacing={5}>
                {
                  RATINGS.map((rating, index) => {
                    return (
                      <RatingItem key={index} rating={rating} index={index} handleRatingClick={handleRatingClick} />
                    )
                  })
                }
                </Stack>
              </Box>
            </Box>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}