import axios from "axios"
import { useEffect, useState } from "react"
import { Box, Button, Input, Spacer, Text, useMediaQuery, useToast, VStack } from "@chakra-ui/react"
import { Navigation } from "./ui"

export const AccountRecoveryPage = () => {
  const toast = useToast()
  const [isLargerThan62] = useMediaQuery('(min-width: 62em)')
  const [email, setEmail] = useState("")

  useEffect(() => {
    document.title = "Account Recovery - Armchair GMs"
  }, [])

  const handleContinueClick = async () => {
    await axios.post(`${process.env.REACT_APP_API_URL}/auth/forgot-password`, { email })
    toast({
      title: `Check your email for your password reset link`,
      status: "success",
      duration: 4000,
      isClosable: true,
    })
  }

  return (
    <Box bg="#1A1A1E" h="100vh">
      <Navigation />
      <VStack
        alignItems="center"
        pt={10}
        px={isLargerThan62 ? '16' : '6'}
        spacing={3}
      >
        <Text fontSize={isLargerThan62 ? "larger" : "large"} fontWeight="bold" color="white">
          Getting back into your Armchair GMs account
        </Text>
        <Text fontSize="medium" fontWeight="medium" pb={10} color="white">
          Tell us some information about your account.
        </Text>
        <Input
          w="40%"
          pr="4.5rem"
          variant="filled"
          fontWeight="bold"
          color="brand.50"
          focusBorderColor="brand.50"
          placeholder="Enter your email"
          onChange={(event) => setEmail(event.target.value)}
        />
        <Button
          size="md"
          color="white"
          bgGradient="linear-gradient(to right, #EB46A6, #E20FF5)"
          disabled={email === ""}
          onClick={handleContinueClick}
        >
          Continue
        </Button>
        <Spacer />
      </VStack>
    </Box>
  )
}