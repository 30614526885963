import { FaFire, FaRegCircle, FaSnowflake } from "react-icons/fa6"
import { useNavigate } from "react-router-dom"
import { HStack, Icon, Spacer, Stack, Text, VStack, useColorModeValue } from "@chakra-ui/react"
import { UserPlayer, UserPlayerValueType } from "../../../types"
import { capitalizeFirstLetter } from "../../../utils"

interface Props {
  index: number
  isEnd: boolean
  userPlayer: UserPlayer
}

export const BoomBustPlayerItem = ({ index, isEnd, userPlayer }: Props) => {
  const bg = useColorModeValue("light.100", "dark.100")
  const bg2 = useColorModeValue("light.200", "dark.200")
  const navigate = useNavigate()
  
  const getUserPlayerIcon = (value: UserPlayerValueType) => {
    switch (value) {
      case UserPlayerValueType.BOOM:
        return <Icon as={FaFire} h={45} w={45} color="playerValue.boom" />
      case UserPlayerValueType.BUST:
        return <Icon as={FaSnowflake} h={45} w={45} color="playerValue.bust" />
      case UserPlayerValueType.OK:
        return <Icon as={FaRegCircle} h={45} w={45} color="playerValue.ok" />
      default:
        return <Icon as={FaFire} h={45} w={45} color="playerValue.boom" />
    }
  }

  return (
    <Stack
      borderTopRadius={index === 0 ? 10 : 0}
      borderBottomRadius={isEnd ? 10 : 0}
      cursor="pointer"
      p={5}
      bg={ index % 2 === 0 ? bg : bg2}
      onClick={() => navigate(`/player/${userPlayer.player.id}`)}
    >
      <HStack pl={2} pr={2}>
        <Stack>
          <Text fontSize="2xl" fontWeight="bold">
            { `${userPlayer.player.firstName} ${userPlayer.player.lastName}` }
          </Text>
          <HStack>
            <Text
              color="grey.400"
              fontSize="md"
              fontWeight="bold"
            >
              { `${userPlayer.player.school.alias ? userPlayer.player.school.alias : userPlayer.player.school.name } - ` }
            </Text>
            <Text fontWeight="bold" color="brand.500" fontSize="md">
              { userPlayer.player.position.name }
            </Text>
          </HStack>
        </Stack>
        <Spacer />
        <VStack>
          <Text fontWeight="bold" fontSize="2xs">
            Rating
          </Text>
          {
            getUserPlayerIcon(userPlayer.value)
          }
          <Text fontWeight="bold" fontSize="sm">
            { capitalizeFirstLetter(userPlayer.value) }
          </Text>
        </VStack>
      </HStack>
    </Stack>
  )
}