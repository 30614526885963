export type Maybe<T> = T | null

export type Scalars = {
  ID: number
  Boolean: boolean
  String: string
  Int: number
}

export enum LeaderboardMenuItemType {
  PLAYERS = "players"
}

export enum NavOption {
  HOME = "home",
  DEMO = "demo",
  BIG_BOARD = "big_board",
  PLAYER = "player",
  PLAYERS = "players",
  INVITATIONAL = "invitational",
  LEADERBOARD = "leaderboard",
  ACCOUNT = "account",
  PROFILE = "profile",
  SETTINGS = "settings",
  FILM = "film",
  STATS = "stats",
}

export enum LootRarity {
  mythic = "mythic",
  rare = "rare",
  uncommon = "uncommon",
  common = "common",
  prize = "prize"
}

export type Art = {
  id: Scalars['ID']
  url: Scalars['String']
  isCurrent: Scalars['Boolean']
}

export type Film = {
  id: Scalars['ID']
  title: Scalars['String']
  url: Scalars['String']
  year: Scalars['Int']
}

export type LeaderboardType = {
  type: LeaderboardMenuItemType
  label: Scalars['String']
}

export type NflDraft = {
  id: Scalars['ID']
  year: Scalars['Int']
  isCurrent: Scalars['Boolean']
}

export type NflDraftSelection = {
  id: Scalars['ID']
  team: Team
  player: Player
  pickNumber: Scalars['Int']
  round: Scalars['Int']
  isDisplayable: Maybe<Scalars['Boolean']>
}

export type NflDraftOrder = {
  id: Scalars['ID']
  pickNumber: Scalars['Int']
  team: Team
}

export type Player = {
  id: Scalars['ID']
  firstName: Scalars['String']
  lastName: Scalars['String']
  school: School
  position: Position
  initialIndex?: Scalars['Int']
  score?: Scalars['Int']
  averageRank: Scalars['Int']
  height?: Scalars['Int']
  weight?: Scalars['Int']
  value?: Scalars['Int']
  nflDraftSelections?: NflDraftSelection[]
  nflDraft?: NflDraft
}

export type PlayerRank = {
  rank: Scalars['Int']
  date: Scalars['String']
  source: Scalars['String']
}

export type PlayerWithDetails = {
  player: Player,
  films: Film[]
}

export type ParentPosition = {
  id: Scalars['ID']
  name: Scalars['String']
}

export type Position = {
  id: Scalars['ID']
  parentPosition: ParentPosition
  name: Scalars['String']
}

export type Rank = {
  id: Scalars['ID']
  label: Scalars['String']
  url: Scalars['String']
}

export type Ranking = {
  score: Scalars['Int']
  ranking: Scalars['Int']
}

export type Rating = {
  label: Scalars['String']
  round: Scalars['Int']
  userRankMin: Scalars['Int']
  userRankMax: Scalars['Int']
}

export type School = {
  id: Scalars['ID']
  name: Scalars['String']
  alias: Scalars['String']
  film: Film[]
}

export type SubscriptionPlan = {
  id: Scalars['ID']
  name: Scalars['String']
  price: Scalars['Int']
}

export type Team = {
  id: Scalars['ID']
  city: Scalars['String']
  abbreviation: Scalars['String']
  name: Scalars['String']
  picture?: Scalars['String']
  color1: Scalars['String']
  color2: Scalars['String']
  count?: Scalars['Int']
}

export type Me = {
  id: Scalars['ID']
  username: Scalars['String']
  isPremium: Scalars['Int']
  profilePicture: Scalars['String']
  team?: Team
  views?: Scalars['Int']
  art?: Art[]
  playerRatings?: PlayerRating[]
}

export type PlayerRating = {
  id: Scalars['ID']
  playerId: Scalars["Int"]
  userRankMin: Scalars["Int"]
  userRankMax: Scalars["Int"]
}

export type User = {
  id: Scalars['ID'],
  username: Scalars['String']
  profilePicture: Scalars['String']
  views?: Scalars['Int']
  team?: Team
  art?: Art[]
}

export enum UserPlayerValueType {
  BOOM = "boom",
  BUST = "bust",
  OK = "ok",
}

export type UserPlayer = {
  id: Scalars['ID']
  player: Player
  value: UserPlayerValueType
  nflDraft: NflDraft
  createdDate: Scalars['String']
}

export type UserPlayerRating = {
  id: Scalars['ID']
  rating: Scalars['Int']
  player: Player
  nflDraftId: Scalars['Int']
  label: Scalars['String']
  round: Scalars['Int']
  userRankMin: Scalars['Int']
  userRankMax: Scalars['Int']
  createdDate: Scalars['String']
  username?: Scalars['String']
}

export type UserPlayerSelection = {
  id: Scalars['ID']
  nflDraftSelection?: NflDraftSelection
  nflDraftId: Scalars['Int']
  index: Scalars['Int']
}

export type UserStats = {
  player: Player
  rating: Rating
  score: Scalars['Int']
  year: Scalars['Int']
  userDelta: Scalars['Int']
  teamDelta: Scalars['Int']
  pickNumber: Scalars['Int']
  averagePlayerMinRank: Scalars['Int']
  performanceRank: Scalars['Int']
}