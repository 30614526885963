import axios from "axios"
import { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { useParams } from "react-router-dom"
import {
  HStack,
  Spinner,
  Stack,
  VStack,
  useToast,
} from "@chakra-ui/react"
import { PlayerDetailsSection, PlayerRatingSection, SetPlayerRatingModal } from "./ui"
import { useCurrentNflContext, useDeviceContext, useUserContext } from "../../hooks"
import { PlayerWithDetails, Rating, UserPlayerValueType } from "../../types"
import { LockedPlayerRatingSection } from "./ui/LockedPlayerRatingSection"

export const PlayerComponent = () => {
  const currentNflDraft = useCurrentNflContext()
  const { isMobile } = useDeviceContext()
  const params = useParams()
  const toast = useToast()
  const { user } = useUserContext()
  const [isLoadingPlayerDetails, setIsLoadingPlayerDetails] = useState(true)
  const [isLoadingPlayerRating, setIsLoadingPlayerRating] = useState(true)
  const [playerWithDetails, setPlayerWithDetails] = useState<PlayerWithDetails | null>(null)
  const [playerRating, setPlayerRating] = useState<Rating | null>(null)
  const [isSetPlayerRatingOpen, setIsSetPlayerRatingOpen] = useState(false)
  const [userPlayerValue, setUserPlayerValue] = useState<UserPlayerValueType | null>(null)

  useEffect(() => {
    const fetchPlayer = async () => {
      const { id } = params
      if (id) {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/players/details`, { playerId: parseInt(id) })
        const { data } = res
        if (data.success) {
          const { player } = data
          setPlayerWithDetails(player)
          setIsLoadingPlayerDetails(false)
          setIsLoadingPlayerRating(false)
        }
      }
    }

    fetchPlayer()
  }, [params])

  useEffect(() => {
    const fetchPlayerRating = async (playerWithDetails: PlayerWithDetails) => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/ratings/player`, { playerId: playerWithDetails.player.id }, { withCredentials: true })
        const { data } = res
        if (data.success) {
          const { userPlayerRating } = data
          setPlayerRating(userPlayerRating)
        }
      } catch(e) {
        console.log(e)
      }
    }

    const fetchUserPlayerValue = async (playerWithDetails: PlayerWithDetails) => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/user-player/${playerWithDetails.player.id}`, { withCredentials: true })
        const { data } = res
        console.log(data)
        if (data.success) {
          const { userPlayer } = data
          setUserPlayerValue(userPlayer.value)
        }
      } catch(e) {
        console.log(e)
      }
    }

    const checkSessionStatus = async (playerWithDetails: PlayerWithDetails) => {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/me`, {}, { withCredentials: true })
      const { data } = res
      if (data.success) {
        await fetchPlayerRating(playerWithDetails)
        await fetchUserPlayerValue(playerWithDetails)
        setIsLoadingPlayerRating(false)
      }
    }

    if (user && playerWithDetails) {
      checkSessionStatus(playerWithDetails)
    }
  }, [user, playerWithDetails])

  const handleRatingClick = async (rating: Rating) => {
    setIsSetPlayerRatingOpen(false)
    try {
      const { id } = params
      if (!id) return
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/ratings/upsert`, {
        playerId: parseInt(id),
        round: rating.round,
        minRank: rating.userRankMin,
        maxRank: rating.userRankMax,
        label: rating.label
      }, { withCredentials: true })
      const { data } = res
      if (data.success) {
        setPlayerRating(rating)
        toast({
          title: "Rating Saved",
          description: "Your rating has been saved",
          status: "success",
          duration: 5000,
          isClosable: true,
        })
      }
    } catch(e) {
      console.log(e)
      toast({
        title: "Error",
        description: "There was an error saving your rating",
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    }
  }

  const getPlayerRatingSection = () => {
    if (isLoadingPlayerRating || !playerWithDetails || !currentNflDraft) {
      return <VStack w="100%"><Spinner /></VStack>
    }

    if (currentNflDraft.id === playerWithDetails.player.nflDraft?.id) {
      return (
        <PlayerRatingSection
          playerWithDetails={playerWithDetails}
          playerRating={playerRating}
          userPlayerValue={userPlayerValue}
          setIsSetPlayerRatingOpen={setIsSetPlayerRatingOpen}
          setUserPlayerValue={setUserPlayerValue}
        />
      )
    } else {
      return (
        <LockedPlayerRatingSection
          playerWithDetails={playerWithDetails}
          playerRating={playerRating}
          userPlayerValue={userPlayerValue}
        />
      )
    }
  }

  return (
    <>
      {
        playerWithDetails && (
          <>
            <Helmet>
              <title>{`${playerWithDetails.player.firstName} ${playerWithDetails.player.lastName} - 2024 NFL Draft - Armchair GMs`}</title>
              <meta name="description" content={`2024 NFL Draft prospect ${playerWithDetails.player.firstName} ${playerWithDetails.player.lastName} - ${playerWithDetails.player.position.name}. Watch his film and predict how well he'll perform in the NFL.`} />
            </Helmet>
            <Stack h="100%">
              {
                isMobile ? (
                  <Stack h="100%">
                    {
                      isLoadingPlayerDetails ? <VStack w="100%"><Spinner /></VStack> : (
                        <PlayerDetailsSection playerWithDetails={playerWithDetails} />
                      )
                    }
                    {
                      getPlayerRatingSection()
                    }
                  </Stack>
                ) : (
                  <HStack h="100%">
                    {
                      isLoadingPlayerDetails ? <VStack w="100%"><Spinner /></VStack> : (
                        <PlayerDetailsSection playerWithDetails={playerWithDetails} />
                      )
                    }
                    {
                      getPlayerRatingSection()
                    }
                  </HStack>
                )
              }
              <SetPlayerRatingModal
                isOpen={isSetPlayerRatingOpen}
                player={playerWithDetails.player}
                onClose={() => setIsSetPlayerRatingOpen(false)}
                handleRatingClick={handleRatingClick}
              />
            </Stack>
          </>
        )
      }
    </>
  )
}