import axios from "axios"
import { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { Box, HStack, Stack, Text } from "@chakra-ui/react"
import { PlayerItem, PositionsSelect, SearchBar } from "../ui"
import { useDeviceContext, useUserContext } from "../../hooks"
import { Player, Position } from "../../types"

export const PlayersComponent = () => {
  const { isMobile } = useDeviceContext()
  const { user } = useUserContext()
  const [searchPlayerText, setSearchPlayerText] = useState<string>("")
  const [allPlayers, setAllPlayers] = useState<Player[]>([])
  const [filteredPlayers, setFilteredPlayers] = useState<Player[]>([])
  const [positions, setPositions] = useState<Position[]>([])
  const [currentPosition, setCurrentPosition] = useState("All Positions")
    
  useEffect(() => {
    const fetchAllPlayers = async () => {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/players/draft/detailed`)
      const { data } = res
      if (data.success) {
        setAllPlayers(data.players)
        setFilteredPlayers(data.players)
      }
    }

    const fetchPositions = async () => {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/positions/all`)
      const { data } = res
      if (data.success) {
        const { positions } = data
        const _positions = [{ id: 999, name: "All Positions" }, ...positions]
        setPositions(_positions)
      }
    }

    fetchAllPlayers()
    fetchPositions()
  }, [])

  const searchPlayers = (name: string) => {
    setSearchPlayerText(name)
    if (name.length === 0) {
      handlePositionChange(currentPosition)
    } else if (name.length > 0) {
      const searchedPlayers = allPlayers.filter(player => { return `${player.firstName} ${player.lastName}`.toLowerCase().includes(name.toLowerCase()) })
      if (currentPosition !== "All Positions") {
        const _filteredPlayers = searchedPlayers.filter((player) => {
          return player.position.name === currentPosition
        })
        setFilteredPlayers(_filteredPlayers)
      } else {
        setFilteredPlayers(searchedPlayers)
      }
    }
  }

  const handlePositionChange = (position: string) => {
    setCurrentPosition(position)
    if (position === "All Positions") {
      setFilteredPlayers(allPlayers)
    } else {
      const _filteredPlayers = allPlayers.filter((player) => {
        return player.position.name === position
      })
      setFilteredPlayers(_filteredPlayers)
    } 
  }

  return (
    <>
      <Helmet>
        <title>2024 NFL Draft Prospect Rankings - Armchair GMs</title>
        <meta name="description" content="Watch film, craft your big board, check your detailed scouting stats. See if you're better at scouting than NFL GMs." />
      </Helmet>
      <Stack w="100%" pt={ isMobile ? 65 : 0 } pl={ isMobile ? 5 : 0 } pr={ isMobile ? 5 : 0 } pb={ isMobile ? 5 : 0 }>
        <Text fontSize="3xl" fontWeight="extrabold">2024 NFL Draft Prospect Rankings</Text>
        <Text fontSize="lg" fontWeight="bold" pt={5}>Watch film, craft your big board, check your detailed scouting stats.</Text>
        <Text fontSize="lg" fontWeight="bold">See if you're better at scouting than NFL GMs.</Text>
        <HStack w="100%" pt={10} pb={10}>
          <SearchBar placeHolder="Search Players" text={searchPlayerText} handleTextChange={searchPlayers} />
          <Box w="50%">
            <PositionsSelect positions={positions} handlePositionChange={handlePositionChange} hideHeader />
          </Box>
        </HStack>
        {
          filteredPlayers.map((player, index) => {
            return (
              <PlayerItem key={index} player={player} isChecked={user?.playerRatings?.some(rating => rating.playerId === player.id)} />
            )
          })
        }
      </Stack>
    </>
  )
}