import { useState } from "react"
import { HStack, Input, Stack, Text } from "@chakra-ui/react"
import { GradientButton, Section } from "../../ui"
import { useDeviceContext } from "../../../hooks"

export const DeactivateAccountSection = () => {
  const { isMobile } = useDeviceContext()
  const [deactivationReason, setDeactivationReason] = useState("")

  return (
    <Section p={5}>
      {
        isMobile ? (
          <Stack pb={5}>
            <Text fontWeight="bold" pr={5} pb={5}>
              Deactivate Your Armchair GMs Account
            </Text>
            <Input
              variant="filled"
              fontWeight="bold"
              color="brand.50"
              focusBorderColor="brand.50"
              placeholder="Tell us why you're leaving"
              onChange={(event) => setDeactivationReason(event.target.value)}
            />
          </Stack>
        ) : (
          <HStack pb={10}>
            <Text fontWeight="bold" pr={5}>
              Deactivate Your Armchair GMs Account
            </Text>
            <Input
              variant="filled"
              fontWeight="bold"
              color="brand.50"
              focusBorderColor="brand.50"
              placeholder="Tell us why you're leaving"
              onChange={(event) => setDeactivationReason(event.target.value)}
            />
          </HStack>
        )
      }
      <GradientButton w="fit-content">
        Deactivate Account
      </GradientButton>
    </Section>
  )
}