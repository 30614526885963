import { useState } from "react"
import { useNavigate } from "react-router-dom"
import {
  Text,
  Flex,
  Spacer,
  Image,
  useColorModeValue,
} from "@chakra-ui/react"
import { GradientButton } from "../../ui"

export const Navigation = () => {
  const [scroll, setScroll] = useState(false)
  const bg = useColorModeValue('light.100', 'dark.100')
  const navigate = useNavigate()
  
  const changeScroll = () =>
    document.body.scrollTop > 80 || document.documentElement.scrollTop > 80
      ? setScroll(true)
      : setScroll(false);
 
  window.addEventListener('scroll', changeScroll);
 
  return (
    <Flex
      h="10vh"
      alignItems="center"
      p="6"
      boxShadow={scroll ? 'base' : 'none'}
      position="sticky"
      top="0"
      zIndex="sticky"
      w="full"
      bg={bg}
    >
        <Image src="https://armchairgms-images.s3.amazonaws.com/logo.png" w={39} mr={5} onClick={() => navigate("/")} cursor="pointer" />
        <Text fontSize="xl" fontWeight="bold" onClick={() => navigate("/")} cursor="pointer">
          Armchair GMs
        </Text>
        <Spacer />
        <GradientButton onClick={() => navigate("/players")}>
          Try Now
        </GradientButton>
    </Flex>
  )
}