import axios from "axios"
import { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { MdRemoveRedEye } from "react-icons/md"
import { useParams } from "react-router-dom"
import styled from "styled-components"
import {
  Flex,
  HStack,
  Icon,
  Stack,
  Text,
} from "@chakra-ui/react"
import { MenuItems, MENU_ITEM_BIG_BOARD, MENU_ITEM_BOOM_BUST, MENU_ITEM_MOCK_DRAFTS } from "../../constants"
import { MenuItemsSection } from "../ui"
import { BigBoardSection, BoomBustSection, UserProfileImages } from "./ui"
import { useUserContext } from "../../hooks"
import { NflDraft, User, UserPlayer, UserPlayerRating } from "../../types"

const UserProfileContainer = styled(Stack)``
UserProfileContainer.defaultProps = {
  width: "66%",
  height: "100%",
  position: "fixed",
  left: "10%",
  top: 0,
  overflowY: "scroll",
  overflowX: "hidden",
  paddingBottom: "80px",
}

export const UserProfileComponent = () => {
  const params = useParams()
  const { user } = useUserContext()
  const [currentUser, setCurrentUser] = useState<User | null>(null)
  const [nflDrafts, setNflDrafts] = useState<NflDraft[]>([])
  const [currentNflDraft, setCurrentNflDraft] = useState<NflDraft | null>(null)
  const [userPlayerRatings, setUserPlayerRatings] = useState<UserPlayerRating[]>([])
  const [userPlayers, setUserPlayers] = useState<UserPlayer[]>([])
  const [hasViewed, setHasViewed] = useState<boolean>(false)
  const [currentMenuItem, setCurrentMenuItem] = useState<string>(MENU_ITEM_BIG_BOARD)
  
  useEffect(() => {
    const fetchAllNflDrafts = async (): Promise<any | null> => {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/draft/all`)
      const { data } = res
      if (data.success) {
        const { nflDrafts } = data
        setNflDrafts(nflDrafts)
        for (let i = 0; i < nflDrafts.length; i++) {
          const nflDraft = nflDrafts[i]
          if (nflDraft.isCurrent === 1) {
            setCurrentNflDraft(nflDraft)
          }
        }
      }
    }

    if (nflDrafts.length === 0) {
      fetchAllNflDrafts()
    }

  }, [nflDrafts])

  useEffect(() => {
    const fetchInfo = async (): Promise<any | null> => {
      const { username } = params
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/users/username`, { username })
      const { data } = res
      if (data.success) {
        setCurrentUser(data.user)
        document.title = `${data.user.username} - Armchair GMs`
        if (!hasViewed) {
          await axios.post(`${process.env.REACT_APP_API_URL}/users/view`, { viewedUserId: data.user.id }, { withCredentials: true })
          setHasViewed(true)
        }
        return data.user
      }
      return null
    }

    if (!currentUser || currentUser.username !== params.username) {
      fetchInfo()
    }

  }, [params, currentUser, hasViewed, setHasViewed])

  useEffect(() => {
    const fetchUserPlayerRatings = async () => {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/ratings`, { nflDraftId: currentNflDraft?.id, userId: currentUser?.id })
      const { data } = res
      if (data.success) {
        setUserPlayerRatings(data.userPlayerRatings)
      }
    }

    const fetchUserPlayers = async () => {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/user-player/all/${currentUser?.id}/${currentNflDraft?.id}`)
      const { data } = res
      if (data.success) {
        setUserPlayers(data.userPlayers)
      }
    }

    if (currentNflDraft && currentUser) {
      fetchUserPlayerRatings()
      fetchUserPlayers()
    }
  }, [currentNflDraft, currentUser])

  const handleNflDraftChange = (value: string) => {
    const year = parseInt(value)
    for (let i = 0; i < nflDrafts.length; i++) {
      const nflDraft = nflDrafts[i]
      if (nflDraft.year === year) {
        setCurrentNflDraft(nflDraft)
        break
      }
    }
  }

  const configureContentView = () => {
    switch (currentMenuItem) {
      case MENU_ITEM_BIG_BOARD:
        return (
          <BigBoardSection
            currentNflDraft={currentNflDraft}
            isUserProfile={currentUser?.id === user?.id}
            nflDrafts={nflDrafts}
            userPlayerRatings={userPlayerRatings}
            handleNflDraftChange={handleNflDraftChange}
          />
        )
      case MENU_ITEM_BOOM_BUST:
        return (
          <BoomBustSection
            currentNflDraft={currentNflDraft}
            isUserProfile={currentUser?.id === user?.id}
            nflDrafts={nflDrafts}
            userPlayers={userPlayers}
            handleNflDraftChange={handleNflDraftChange}
          />
        )
      case MENU_ITEM_MOCK_DRAFTS:
        return (
          <Text>
            Mock Drafts
          </Text>
        )
      default:
        return (
          <BigBoardSection
            currentNflDraft={currentNflDraft}
            isUserProfile={currentUser?.id === user?.id}
            nflDrafts={nflDrafts}
            userPlayerRatings={userPlayerRatings}
            handleNflDraftChange={handleNflDraftChange}
          />
        )
    }
  }

  return (
    <Flex>
      {
        currentUser && (
          <>
            <Helmet>
              <title>{ `NFL Big Board - ${currentUser.username} - Armchair GMs` }</title>
              <meta name="description" content={ `NFL Big Board for ${currentUser.username}. Check out their NFL player ratings.` } />
            </Helmet>
            <UserProfileContainer mb={5} pr={10}>
              <UserProfileImages currentUser={currentUser} />
              <Stack position="relative" pl={10} top={-148}>
                <Text fontSize="2xl" fontWeight="bold" pt={5}>
                  { currentUser?.username }
                </Text>
                <HStack spacing={0} pb={5}>
                  <Icon as={MdRemoveRedEye} color="#8b8b9b" size={15} />
                  <Text color="#8b8b9b" pl={2} fontSize={16} fontWeight="bold">
                    { `${currentUser?.views?.toLocaleString()} Views` }
                  </Text>
                </HStack>
                <MenuItemsSection currentMenuItem={currentMenuItem} menuItems={MenuItems} setCurrentMenuItem={setCurrentMenuItem} />
                {
                  configureContentView()
                }
              </Stack>
            </UserProfileContainer>
          </>
        )
      }
    </Flex>
  )
}