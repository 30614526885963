import { extendTheme, type ThemeConfig } from '@chakra-ui/react'

// 2. Add your color mode config
const config: ThemeConfig = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
}

// 3. extend the theme
const theme = extendTheme({
  config,
  colors: {
    brand: {
      main: "#1B0125",
      25: "#1B0125",
      50: "#E111F2",
      100: "#131A4B",
      200: "#E20FF5",
      300: "#212D81",
      400: "#37438E",
      500: "#FBA426",
      600: "#1B0125",
    },
    grey: {
      100: "#F8F8F8",
      200: "#E2E9F3",
      300: "#8b8b9b",
      400: "#808080",
      500: "#718096"
    },
    light: {
      background: "#E2E9F3",
      text: "#000000",
      100: "#FFFFFF",
      200: "#F9FBFD",
      300: "#FAFBFD",
      400: "#EDF2F8",
    },
    dark: {
      background: "#040931",
      text: "#FFFFFF",
      100: "#242C5D",
      200: "#323D82",
      300: "#0c1a8f",
      400: "#6774C4",
    },
    playerValue: {
      boom: "#FFD700",
      bust: "#87CEEB",
      ok: "#82859A",
    },
  },
  styles: {
    global: () => ({
      body: {
        fontFamily: "Montserrat",
      }
    })
  },
})

export default theme