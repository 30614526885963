import {
  Button,
  chakra,
  useColorModeValue
} from "@chakra-ui/react"

export const OutlineButton = chakra((props) => {
  const color = useColorModeValue("grey.400", "white")

  return (
    <Button
      {...props}
      variant="outline"
      borderColor={color}
      color={color}
    />
  )
})