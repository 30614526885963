import { Select } from "@chakra-ui/react"
import { NflDraft } from "../../types"

interface Props {
  nflDraft?: string
  nflDrafts: NflDraft[]
  handleNflDraftChange: (value: string) => void
}

export const NflDraftYearSelect = ({ nflDraft, nflDrafts, handleNflDraftChange }: Props) => {
  return (
    <>
      {
        nflDrafts.length > 0 && (
          <Select
            variant="filled"
            w="30%"
            bg="white"
            color="brand.50"
            fontWeight="bold"
            value={nflDraft ? nflDraft : nflDrafts[0].year}
            onChange={(event) => handleNflDraftChange(event.target.value)}
          >
            {
              nflDrafts.map(nflDraft => {
                return (
                  <option key={nflDraft.year} value={nflDraft.year}>{ nflDraft.year }</option>            
                )
              })
            }
          </Select>
        )
      
      }
    </>
  )
}