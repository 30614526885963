import {
  Box,
  Flex,
  HStack,
  Image,
  Spacer,
  Stack,
  Text,
} from "@chakra-ui/react"
import { useDeviceContext } from "../../../hooks"
import { PlayerWithDetails } from "../../../types"
import { convertCentimetersToFeet } from "../../../utils"

interface Props {
  playerWithDetails: PlayerWithDetails
}

export const PlayerDetailsSection = ({ playerWithDetails }: Props) => {
  const { isMobile, screenWidth } = useDeviceContext()

  const getThumbnailFromYoutubeVideo = (url: string) => {
    const videoId = url.split("v=")[1]
    return `https://img.youtube.com/vi/${videoId}/0.jpg`
  }

  return (
    <Stack pt={ isMobile ? 65 : 0 } pl={10} pr={10} pb={ isMobile ? 20 : 0 } w={ isMobile ? "100%" : "50%" } h="100%" bg="brand.100">
      <Spacer />
      <Text fontSize="5xl" fontWeight="bold" color="white">
        { `${playerWithDetails.player.firstName} ${playerWithDetails.player.lastName}` }
      </Text>
      <Text fontSize="xl" fontWeight="bold" color="white">
        { playerWithDetails.player.school.name }
      </Text>
      <Text fontSize="xl" color="white">
        { playerWithDetails.player.position.name }
      </Text>
      <HStack pt={20} pb={20} spacing={10}>
        <Stack spacing={0}>
          <Text fontSize="xs" fontWeight="bold" color="grey.400">
            Height
          </Text>
          <Text fontSize="sm" fontWeight="bold" color="white">
            { playerWithDetails.player.height ? convertCentimetersToFeet(playerWithDetails.player.height) : "N/A" }
          </Text>
        </Stack>
        <Stack spacing={0}>
          <Text fontSize="xs" fontWeight="bold" color="grey.400">
            Weight
          </Text>
          <Text fontSize="sm" fontWeight="bold" color="white">
            { playerWithDetails.player.height ? `${playerWithDetails.player.weight} lbs` : "N/A" }
          </Text>
        </Stack>
      </HStack>
      <Text fontWeight="bold" color="white">
        Film Room:
      </Text>
      <Flex overflowX="scroll">
        {
          playerWithDetails.films.map(film => {
            return (
              <Box
                key={film.id}
                cursor="pointer"
                pr={5}
                onClick={() => window.open(`/film/${film.id}`)}
              >
                <Image w={ isMobile ? screenWidth * 0.5 : screenWidth * 0.1 } src={getThumbnailFromYoutubeVideo(film.url)} />
                <Text w={ isMobile ? screenWidth * 0.5 : screenWidth * 0.1 } fontSize="sm" pt={5} fontWeight="medium" color="white">
                  { film.title }
                </Text>
              </Box>
            )
          })
        }
      </Flex>
      <Spacer />
    </Stack>
  )
}