import axios from "axios"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Box, Button, HStack, Spacer, Stack, Text, VStack } from "@chakra-ui/react"
import { useDeviceContext } from "../../hooks"
import { SearchBar } from "../ui"
import { Player, User, UserPlayerRating } from "../../types"
import { SearchDropdown } from "./ui"
import { RecentPlayerRatingsList } from "./ui/RecentPlayerRatingsList"

export const HomeComponent = () => {
  const { screenWidth } = useDeviceContext()
  const navigate = useNavigate()
  
  const [searchText, setSearchText] = useState<string>("")
  const [users, setUsers] = useState<User[]>([])
  const [filteredUsers, setFilteredUsers] = useState<User[]>([])
  const [players, setPlayers] = useState<Player[]>([])
  const [filteredPlayers, setFilteredPlayers] = useState<Player[]>([])
  const [isSearchBarFocused, setIsSearchBarFocused] = useState(false)
  const [recentPlayerRatings, setRecentPlayerRatings] = useState<UserPlayerRating[]>([])

  useEffect(() => {
    const fetchRecentPlayerRatings = async () => {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/ratings/recent`)
      const { data } = res
      if (data.success) {
        const { userPlayerRatings } = data
        setRecentPlayerRatings(userPlayerRatings)
      }
    }
    fetchRecentPlayerRatings()
  }, [])

  useEffect(() => {
    const fetchUsers = async () => {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/users/search`, { name: searchText })
      const { data } = res
      if (data.success) {
        setUsers(data.users)
        setFilteredUsers(data.users)
      }
    }

    const fetchPlayers = async () => {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/players/search`, { name: searchText })
      const { data } = res
      if (data.success) {
        setPlayers(data.players)
        setFilteredPlayers(data.players)
      }
    }

    if (searchText.length > 3) {
      const _filteredUsers = users.filter(user => { return user.username.toLowerCase().includes(searchText.toLowerCase()) })
      const _filteredPlayers = players.filter(player => { return `${player.firstName} ${player.lastName}`.toLowerCase().includes(searchText.toLowerCase()) })
      setFilteredUsers(_filteredUsers)
      setFilteredPlayers(_filteredPlayers)
    } else if (searchText.length === 3) {
      fetchUsers()
      fetchPlayers()
    } else {
      setUsers([])
      setFilteredUsers([])
      setPlayers([])
      setFilteredPlayers([])
    }
  }, [searchText])

  const searchPlayersAndUser = (name: string) => {
    setSearchText(name)
  }

  const getView = () => {
    return (
        <>

        </>
    )
  }

  return (
    <Stack h="100%">
      {
        screenWidth < 1400 ? (
          <Stack w="100%" pt={50}>
            { getView() }
          </Stack>
        ) : (
          <VStack h="100%" w="100%" pr={5}>
            <VStack
              w="100%"
              bgImage={`url("https://freight.cargo.site/t/original/i/287fee0024d8eb8880dd56089b3e271f37311e46ff70d59dded4e0eb4e513fc4/VALORANT_2023_Premier_StyleFrame_ShieldDark-1-2048x1152.jpeg")`}
              backgroundSize="cover"
              backgroundPosition="center"
            >
              <VStack
                w="100%"
                h="100%"
                bgGradient="linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.9))"
                p={0}
                m={0}
              >
                <Box p={5}>
                  <SearchBar
                    bgColor="rgba(255, 255, 255, 0.5)"
                    placeHolder="Search"
                    text={searchText}
                    width="450px"
                    handleTextChange={searchPlayersAndUser}
                    handleIsFocused={setIsSearchBarFocused}
                  />
                  <SearchDropdown
                    players={filteredPlayers}
                    users={filteredUsers}
                    isVisible={ isSearchBarFocused && (filteredPlayers.length > 0 || filteredUsers.length > 0) }
                  />
                </Box>
                <HStack w="100%" p={10}>
                  <Stack w="20%">
                    <Text color="white" fontSize="3xl" fontWeight="bold" pb={10}>
                      Compete Against Other Scouts
                    </Text>
                    <Button bg="white" color="black" onClick={() => navigate(`/invitational`)}>
                      Enter Now
                    </Button>
                  </Stack>
                  <Spacer />
                </HStack>
                <Spacer />
              </VStack>
            </VStack>
            <Stack w="100%">
              <Text fontSize="xl" fontWeight="bold" pt={10} pl={10}>
                Recent Player Ratings
              </Text>
              <RecentPlayerRatingsList userPlayerRatings={recentPlayerRatings}/>
            </Stack>
          </VStack>
        )
      }
    </Stack>
  )
}