import axios from "axios"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { 
  Box,
  Button,
  chakra,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Select,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react"
import { setSessionCookie, useDeviceContext, useTeamsContext, useUserContext } from "../../../hooks"
import { Team } from "../../../types"
import { GradientButton } from "../../ui"

const SignUpInput = chakra((props) => {
  return (
    <Input
      {...props}
      variant="filled"
      fontWeight="bold"
      bg="#37438E"
      _placeholder={{ color: "white" }}
      color="brand.50"
      focusBorderColor="brand.50"
    />
  )
})


interface Props {
  onClose?: () => void
}

export const SignUpPanel = ({ onClose }: Props) => {
  const { isMobile } = useDeviceContext()
  const navigate = useNavigate()
  const toast = useToast()
  const teams = useTeamsContext()
  const { setUser } = useUserContext()
  const [isSignUp, setIsSignUp] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [email, setEmail] = useState("")
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [verifiedPassword, setVerifiedPassword] = useState("")
  const [selectableTeams, setSelectableTeams] = useState<any[]>([])
  const [currentTeam, setCurrentTeam] = useState<Team | null>(null)
  const [showPasswordDontMatchStr, setShowPasswordDontMatchStr] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showVerifyPassword, setShowVerifyPassword] = useState(false)

  useEffect(() => {
    if (selectableTeams.length === 0 && teams.length > 0) {
      const _selectableTeams = [null, ...teams]
      setSelectableTeams(_selectableTeams)
    }
  }, [selectableTeams, setSelectableTeams, teams])

  const makeToast = (title: string, status: "success" | "error") => {
    toast({
      title,
      status,
      duration: 4000,
      isClosable: true,
    })
  }

  const handleSignInClick = async () => {
    setShowPasswordDontMatchStr(false)
    setIsLoading(true)
    try {
      if (isSignUp) {
        if (password !== verifiedPassword) {
          setShowPasswordDontMatchStr(true)
        } else {
          const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/signup`, 
           { email, password, username, favoriteTeamId: currentTeam ? currentTeam.id : null }, { withCredentials: true })
          const { data } = res
          if (data.success) {
            setSessionCookie(data.user)
            setUser(data.user)
            makeToast(`Welcome ${data.user.username}!`, "success")
          } else {
            makeToast(data.error, "error")
          }
        }
      } else {
        const loginRes = await axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, { email, username, password }, { withCredentials: true })
        const { data } = loginRes
        if (data.success) {
          makeToast(`Welcome back ${data.user.username}!`, "success")
          setSessionCookie(data.user)
          setUser(data.user)
          if (onClose) {
            onClose()
          }
        } else {
          makeToast(data.error, "error")
        }
      }
      setIsLoading(false)
    } catch(e: any) {
      setIsLoading(false)
      toast({
        title: `Failed to ${isSignUp ? "create account" : "login"}. ${e.message}`,
        status: "error",
        duration: 4000,
        isClosable: true,
      })
    }
  }

  const handleTeamChange = (teamName: string) => {
    for (let i = 0; i < teams.length; i++) {
      const team = teams[i]
      const _teamName = `${team.city} ${team.name}`
      if (teamName === _teamName) {
        setCurrentTeam(team)
        break
      }
    }
  }

  return (
    <Box borderLeftRadius={10} borderRightRadius={isMobile || onClose != null ? 10 : 0} bg="#242C5D" w="100%" p={8}>
      <Text fontSize="2xl" fontWeight="bold" color="white">
        { isSignUp ? "Create new account" : "Welcome back" }
      </Text>
      <SignUpInput
        mt={5}
        placeholder="Email"
        onChange={(event: any) => setEmail(event.target.value)}
      />
      {
        isSignUp && (
          <SignUpInput
            mt={5}
            placeholder="Create Username"
            onChange={(event: any) => setUsername(event.target.value)}
          />
        )
      }
      <InputGroup size="md" mt={5}>
        <SignUpInput
          pr="4.5rem"
          type={showPassword ? "text" : "password"}
          placeholder="Password"
          onChange={(event: any) => setPassword(event.target.value)}
        />
        <InputRightElement width="4.5rem">
          <Button h="1.75rem" size="sm" color="white" bgGradient="linear-gradient(to right, #EB46A6, #E20FF5)" onClick={() => setShowPassword(!showPassword)}>
            {showPassword ? "Hide" : "Show"}
          </Button>
        </InputRightElement>
      </InputGroup>
      {
        isSignUp && (
          <InputGroup size="md" mt={5}>
            <SignUpInput
              pr="4.5rem"
              type={showVerifyPassword ? "text" : "password"}
              placeholder="Verify Password"
              onChange={(event: any) => setVerifiedPassword(event.target.value)}
            />
            <InputRightElement width="4.5rem">
              <Button h="1.75rem" size="sm" color="white" bgGradient="linear-gradient(to right, #EB46A6, #E20FF5)" onClick={() => setShowVerifyPassword(!showVerifyPassword)}>
                {showVerifyPassword ? "Hide" : "Show"}
              </Button>
            </InputRightElement>
          </InputGroup>
        )
      }
      {
        showPasswordDontMatchStr && (
          <Text fontSize="md" color="red.300" mt={5}>
            Passwords don't match
          </Text>
        )
      }
      {
        isSignUp && (
          <Stack pt={5}>
            <Text fontSize="sm" fontWeight="bold" color="white" mt={5}>
              Favorite Team
            </Text>
            <Select
              variant="filled"
              w="100%"
              bg="white"
              color="brand.50"
              fontWeight="bold"
              onChange={(event) => handleTeamChange(event.target.value)}
            >
              {
                selectableTeams.map((team, index) => {
                  return (
                    <option key={index} value={team ? `${team.city} ${team.name}` : "None"}>{ team ? `${team.city} ${team.name}` : "None" }</option>
                  )
                })
              }
            </Select>
          </Stack>
        )
      }
      <GradientButton isLoading={isLoading} w="fit-content" mt={5} onClick={handleSignInClick}>
        { isSignUp ? "Create account" : "Login" }
      </GradientButton>
      <Box h={0.3} mt={10} w="100%" bg="white" />
      <Stack pt={5}>
        {
          !isSignUp && (
            <HStack>
              <Text fontSize="xs" color="white">
                Having trouble logging in?
              </Text>
              <Link color="white" fontSize="xs" fontWeight="bold" onClick={() => navigate(`/account-recovery`)}>Click Here</Link>
            </HStack>
          )
        }
        <HStack>
          <Text fontSize="xs" color="white">
            { isSignUp ? "Already have an account?" : "Don't have an account?" }
          </Text>
          <Link color="white" fontSize="xs" fontWeight="bold" onClick={() => setIsSignUp(!isSignUp)}>{ isSignUp ? "Log in" : "Sign up" }</Link>
        </HStack>
      </Stack>
    </Box>
  )
}