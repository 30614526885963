import React, { useContext } from 'react'

export const DeviceContext = React.createContext<{
    isMobile: boolean
    screenWidth: number
    screenHeight: number
}>({
    isMobile: window.innerWidth < 1200,
    screenWidth: window.innerWidth,
    screenHeight: window.innerHeight,
})

export const useDeviceContext = () => {
    return useContext(DeviceContext)
}

export const useDevice = () => {
    const { isMobile, screenWidth, screenHeight } = useDeviceContext()
    return { isMobile, screenWidth, screenHeight }
}