import { Stack, Text } from "@chakra-ui/react"
import { DeactivateAccountSection, FavoriteTeamSection, PasswordSection, UsernameSection } from "./ui"
import { Section } from "../ui"
import { useDeviceContext } from "../../hooks"

export const SettingsComponent = () => {
  const { isMobile } = useDeviceContext()
  
  return (
    <Stack spacing={10}>
      <Text fontSize="2xl" fontWeight="bold" pb={isMobile ? 0 : 5}>
        Settings
      </Text>
      <Section>
        <UsernameSection />
        <PasswordSection />
        <FavoriteTeamSection />
      </Section>
      <DeactivateAccountSection />
    </Stack>
  )
}