import axios from "axios"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { 
  Divider,
  HStack,
  Link,
  Spacer,
  Switch,
  Text,
  useColorMode,
  VStack,
} from "@chakra-ui/react"
import { SignUpPanel } from "./SignUpPanel"
import { SearchDropdown } from "./SearchDropdown"
import { ProfileCard, SearchBar } from "../../ui"
import { removeSessionCookie, useUserContext } from "../../../hooks"
import { LootRarity, Player, User } from "../../../types"

export const UserPanel = () => {
  const { colorMode, toggleColorMode } = useColorMode()
  const navigate = useNavigate()
  const { user } = useUserContext()

  const [searchText, setSearchText] = useState<string>("")
  const [users, setUsers] = useState<User[]>([])
  const [filteredUsers, setFilteredUsers] = useState<User[]>([])
  const [players, setPlayers] = useState<Player[]>([])
  const [filteredPlayers, setFilteredPlayers] = useState<Player[]>([])
  const [isSearchBarFocused, setIsSearchBarFocused] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  useEffect(() => {
    const fetchUsers = async () => {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/users/search`, { name: searchText })
      const { data } = res
      if (data.success) {
        setUsers(data.users)
        setFilteredUsers(data.users)
      }
    }

    const fetchPlayers = async () => {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/players/search`, { name: searchText })
      const { data } = res
      if (data.success) {
        setPlayers(data.players)
        setFilteredPlayers(data.players)
      }
    }

    if (searchText.length > 3) {
      const _filteredUsers = users.filter(user => { return user.username.toLowerCase().includes(searchText.toLowerCase()) })
      const _filteredPlayers = players.filter(player => { return `${player.firstName} ${player.lastName}`.toLowerCase().includes(searchText.toLowerCase()) })
      setFilteredUsers(_filteredUsers)
      setFilteredPlayers(_filteredPlayers)
    } else if (searchText.length === 3) {
      fetchUsers()
      fetchPlayers()
    } else {
      setUsers([])
      setFilteredUsers([])
      setPlayers([])
      setFilteredPlayers([])
    }
  }, [searchText])

  useEffect(() => {
    const checkSessionStatus = async () => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/me`, {}, { withCredentials: true })
        if (res.status === 200) {
          setIsLoggedIn(true)
        } else {
          removeSessionCookie()
          setIsLoggedIn(false)
        }
      } catch(e) {
        removeSessionCookie()
        setIsLoggedIn(false)
      }
    }

    if (user) {
      checkSessionStatus()
    }
  }, [user])

  const searchPlayersAndUser = (name: string) => {
    setSearchText(name)
  }

  return (
    <VStack h="100%" pt={20}>
      <VStack w="100%" pl={10} pr={2}>
        <SearchBar
          bgColor="rgba(255, 255, 255, 0.5)"
          placeHolder="Search"
          width="100%"
          text={searchText}
          handleTextChange={searchPlayersAndUser}
          handleIsFocused={setIsSearchBarFocused}
        />
        <SearchDropdown
          players={filteredPlayers}
          users={filteredUsers}
          isVisible={ isSearchBarFocused && (filteredPlayers.length > 0 || filteredUsers.length > 0) }
        />
      </VStack>
      <VStack pr={2} pl={10} w="100%">
        {
          isLoggedIn && user ? (
            <>
              <VStack
                borderRadius={10}
                backdropFilter="blur(33px)"
                bg="dark.100"
                w="100%"
              >
                <VStack alignItems="center" borderRadius={10} bg="transparent" p={4}>
                  <ProfileCard width="19vw" height="19vw" isAnimated={true} url={user.profilePicture} rarity={LootRarity.prize} />
                  <Text as={Link} color="white" fontSize={20} fontWeight="bold" pt={5} pb={5} onClick={() => navigate(`/profile/${user?.username}`)}>
                    { user?.username }
                  </Text>
                  <Divider pt={5} />
                  <HStack pt={2} pb={5}>
                    <Switch onChange={toggleColorMode} isChecked={ colorMode === 'dark' } colorScheme="white" />
                    <Text fontSize="sm" color="white">{ colorMode === 'light' ? 'Light' : 'Dark' } Mode</Text>
                  </HStack>
                </VStack>
              </VStack>
            </>
          ) : (
            <SignUpPanel />
          )
        }
      </VStack>
      <Spacer />
    </VStack>
  )
}