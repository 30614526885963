import { Box } from "@chakra-ui/react"
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import { DashboardPage } from "./DashboardPage"
import { AccountRecoveryPage, ResetPasswordPage } from "../Help"
import { ContactUsPage, LandingPage, PrivacyPolicyPage, TermsPage } from "../Landing"
import { NavOption } from "../../types"
import axios from "axios"

export const Main = () => {
  const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL, withCredentials: true })

  return (
    <Box>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={ <DashboardPage currentNavOption={NavOption.PLAYERS} axios={axiosInstance} /> } />
          {/* <Route path="/invitational" element={ <DashboardPage currentNavOption={NavOption.INVITATIONAL} axios={axiosInstance} /> } /> */}
          <Route path="/contact-us" element={ <ContactUsPage /> }  />
          <Route path="/players" element={ <DashboardPage currentNavOption={NavOption.PLAYERS} axios={axiosInstance} /> }  />
          <Route path="/player/:id" element={ <DashboardPage currentNavOption={NavOption.PLAYER} axios={axiosInstance} /> }  />
          {/* <Route path="/leaderboard" element={ <DashboardPage currentNavOption={NavOption.LEADERBOARD} axios={axiosInstance} /> } /> */}
          {/* <Route path="/rewards" element={ <DashboardPage currentNavOption={NavOption.REWARDS} axios={axiosInstance} /> } /> */}
          <Route path="/profile/:username" element={ <DashboardPage currentNavOption={NavOption.PROFILE} axios={axiosInstance} /> } />
          <Route path="/demo" element={ <DashboardPage currentNavOption={NavOption.DEMO} axios={axiosInstance} /> } />
          {/* <Route path="/my-stats" element={ <DashboardPage currentNavOption={NavOption.MY_STATS} axios={axiosInstance} /> } /> */}
          {/* <Route path="/loot/:lootId" element={ <DashboardPage currentNavOption={NavOption.LOOT} axios={axiosInstance} /> } /> */}
          <Route path="/film/:id" element={ <DashboardPage currentNavOption={NavOption.FILM} axios={axiosInstance} /> } />
          <Route path="/stats" element={ <DashboardPage currentNavOption={NavOption.STATS} axios={axiosInstance} /> } />
          {/* <Route path="/badges/edit" element={ <DashboardPage currentNavOption={NavOption.MY_BADGES} axios={axiosInstance} /> } /> */}
          <Route path="/settings" element={ <DashboardPage currentNavOption={NavOption.SETTINGS} axios={axiosInstance} /> } />
          <Route path="/about" element={ <LandingPage /> } />
          <Route path="/privacy" element={ <PrivacyPolicyPage /> } />
          <Route path="/terms" element={ <TermsPage /> } />
          <Route path="/account-recovery" element={ <AccountRecoveryPage /> } />
          <Route path="/reset-password/:token" element={ <ResetPasswordPage /> } />
          <Route path="*" element={<Navigate to="/players" />} />
        </Routes>
      </BrowserRouter>
    </Box>
  )
}