import {
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react"
import { BoomBustBox } from "./"
import { useDeviceContext } from "../../../hooks"
import { PlayerWithDetails, Rating, UserPlayerValueType } from "../../../types"

interface Props {
  playerWithDetails: PlayerWithDetails
  playerRating: Rating | null
  userPlayerValue: UserPlayerValueType | null
}

export const LockedPlayerRatingSection = ({ playerWithDetails, playerRating, userPlayerValue }: Props) => {
  const { isMobile } = useDeviceContext()

  return (
    <VStack w={ isMobile ? "100%" : "50%" } h="100%" p={10} spacing="5">
      <Spacer />
      <Text fontSize="xs" fontWeight="bold">
        { `Your rating for ${playerWithDetails.player.firstName} ${playerWithDetails.player.lastName}` }
      </Text>
      <BoomBustBox
        userPlayerValue={userPlayerValue ?? undefined}
        isFilled={true}
        isLocked={true}
      />
      {
        playerRating && (
          <VStack bgGradient="linear-gradient(to right, #EB46A6, #E20FF5)" borderRadius={5} p={3} w="70%" mt={5}>
            <Text fontSize="xs" fontWeight="bold" color="white">
              Your Big Board Rating:
            </Text>
            <Text fontSize="lg" fontWeight="bold" color="white">
              { playerRating.label }
            </Text>
          </VStack>
        )
      }
      <Spacer />
    </VStack> 
  )
}