import { Box, Image, VStack } from "@chakra-ui/react"
import { useDeviceContext } from "../../../hooks"
import { LootRarity, User } from "../../../types"
import { ProfileCard } from "../../ui"

interface Props {
  currentUser: User
}

const UserProfilePicture = ({ currentUser }: Props) => {
  const { isMobile } = useDeviceContext()

  return (
    <Box position="relative" pl={isMobile ? 5 : 10} top={isMobile ? -24 : -40} w={isMobile ? "100vw" : "168px"} h="168px">
      <VStack
        borderRadius={10}
        width="168px"
        bg="transparent"
        p={0.43}
      >
        <VStack alignItems="center" width="168px" h="168px" borderRadius={10} bg="transparent">
          <ProfileCard width="168px" height="168px" isAnimated={true} url={currentUser.profilePicture ?? ""} rarity={LootRarity.prize} />
        </VStack>
      </VStack>
    </Box>
  )
}

const UserProfileBanner = ({ currentUser }: Props) => {
  const { isMobile } = useDeviceContext()
  
  const convertHexToRGB = (hex: string) => {
    const _hex = hex.replace(/^#/, '');

    // Split the hex string into R, G, and B components
    const r = parseInt(_hex.slice(0, 2), 16)
    const g = parseInt(_hex.slice(2, 4), 16)
    const b = parseInt(_hex.slice(4, 6), 16)
  
    // Return the RGB color as a string
    return `rgb(${r}, ${g}, ${b}, 0.7)`
  }

  return (
    <Box h={200} w="100%">
      <Box 
        w="100%"
        h={200}
        backdropFilter="blur(33px)"
        bg="grey"
        bgGradient={currentUser?.team ? `linear(to-r, ${convertHexToRGB(currentUser.team.color1)}, ${convertHexToRGB(currentUser.team.color2)})` : `linear-gradient(to right, #EB46A6, #E20FF5)`}
        bgPosition="center"
        bgRepeat="no-repeat"
        bgSize="cover"
      />
      <Image w={20} position="absolute" zIndex={100} top="50px" right={isMobile ? 2 : 20} src={currentUser?.team ? currentUser.team?.picture : "https://armchairgms-team-logos.s3.us-west-2.amazonaws.com/outline/nfl.png"} />
    </Box>
  )
}

export const UserProfileImages = ({ currentUser }: Props) => {
  return (
    <>
      <UserProfileBanner currentUser={currentUser} />
      <UserProfilePicture currentUser={currentUser} />
    </>
  )
}