import axios from "axios"
import { getUser, setSessionCookie } from "../hooks"
import { Rating, User } from "../types"

export const fetchMe = async () => {
  try {
    const _user = await getUser()
    if (!_user) {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/me`, {}, { withCredentials: true })
      const { data } = res
      if (data.success) {
        setSessionCookie(data.user)
        return data.user
      }
    }
  } catch (err) {
    console.log(err)
  }
}

export const convertCentimetersToFeet = (centimeters: number) => {
  const feet = Math.floor(centimeters / 30.48)
  const inches = Math.round((centimeters % 30.48) / 2.54)
  return `${feet}'${inches}"`
}

export const getProfilePictureFromUser = (user: User) => {
  return user.art?.find(a => a.isCurrent)?.url
}

export const RATING_TOP_TEN = "Top 10"
export const RATING_TOP_TWENTY = "Top 20"
export const RATING_FIRST_ROUND = "Top 32"
export const RATING_TOP_FIFTY = "Top 50"
export const RATING_SECOND_ROUND = "Top 64"
export const RATING_THIRD_ROUND = "3rd Round"
export const RATING_FOURTH_ROUND = "4th Round"
export const RATING_FIFTH_ROUND = "5th Round"
export const RATING_SIXTH_ROUND = "6th Round"
export const RATING_SEVENTH_ROUND = "7th Round"
export const RATING_UNDRAFTED = "Undrafted"

export const RATINGS: Rating[] = [
  {
    label: RATING_TOP_TEN,
    round: 1,
    userRankMin: 1,
    userRankMax: 10
  },
  {
    label: RATING_TOP_TWENTY,
    round: 1,
    userRankMin: 11,
    userRankMax: 20,
  },
  {
    label: RATING_FIRST_ROUND,
    round: 1,
    userRankMin: 21,
    userRankMax: 32
  },
  {
    label: RATING_TOP_FIFTY,
    round: 2,
    userRankMin: 33,
    userRankMax: 50
  },
  {
    label: RATING_SECOND_ROUND,
    round: 2,
    userRankMin: 51,
    userRankMax: 64,
  },
  {
    label: RATING_THIRD_ROUND,
    round: 3,
    userRankMin: 65,
    userRankMax: 100
  },
  {
    label: RATING_FOURTH_ROUND,
    round: 4,
    userRankMin: 101,
    userRankMax: 140,
  },
  {
    label: RATING_FIFTH_ROUND,
    round: 5,
    userRankMin: 141,
    userRankMax: 180,
  },
  {
    label: RATING_SIXTH_ROUND,
    round: 6,
    userRankMin: 181,
    userRankMax: 220,
  },
  {
    label: RATING_SEVENTH_ROUND,
    round: 7,
    userRankMin: 221,
    userRankMax: 300,
  },
  {
    label: RATING_UNDRAFTED,
    round: 8,
    userRankMin: 301,
    userRankMax: 999,
  }
]

export const getLabelFromMinRank = (minRank: number): string => {
  const rating = RATINGS.find(rating => rating.userRankMin >= minRank)
  return rating ? rating.label : ""
}

export const getRatingTopText = (rating: Rating) => {
  if (rating.round === 8) { 
    return ""
  }

  if (rating.userRankMin === 1 || rating.userRankMin === 11 || rating.userRankMin === 21 || rating.userRankMin === 33) {
    return "Top"
  } else {
    return "Round"
  }
}

export const getRatingBottomText = (rating: Rating) => {
  if (rating.round === 8) { 
    return "UDFA"
  }

  if (rating.userRankMin === 1 || rating.userRankMin === 11 || rating.userRankMin === 21 || rating.userRankMin === 33) {
    return rating.userRankMax
  } else {
    return rating.round
  }
}

export const capitalizeFirstLetter = (word: string): string => {
  return word.charAt(0).toUpperCase() + word.slice(1)
}