import { Box, HStack, Select } from "@chakra-ui/react"
import { useDeviceContext } from "../../hooks"

interface Props {
  currentMenuItem: string
  menuItems: string[]
  setCurrentMenuItem: (menuItem: string) => void
}

interface MenuSelectionProps {
  currentMenuItem: string
  menuItems: string[]
  setCurrentMenuItem: (menuItem: string) => void
}

const MenuSelection = ({ currentMenuItem, menuItems, setCurrentMenuItem }: MenuSelectionProps) => {
  const { isMobile } = useDeviceContext()

  return (
    <>
      {
        isMobile ? (
          <Select
            variant="filled"
            w="50%"
            bg="white"
            color="brand.50"
            fontWeight="bold"
            onChange={(event) => setCurrentMenuItem(event.target.value)}
          >
            {
              menuItems.map(menuItem => {
                return (
                  <option key={menuItem} value={menuItem}>{ menuItem }</option>
                )
              })
            }
          </Select>
        ) : (
          <>
            {
              menuItems.map(menuItem => {
                return (
                  <Box
                    key={menuItem}
                    w="50%"
                    bgGradient={ currentMenuItem === menuItem ? "linear-gradient(to right, #EB46A6, #E20FF5)" : "linear-gradient(to right, #2E3640, #2E3640)" }
                    onClick={() => setCurrentMenuItem(menuItem)}
                    p={5}
                    textAlign="center"
                    color="white"
                    fontWeight="bold"
                    cursor="pointer"
                    borderLeftRadius={menuItem === menuItems[0] ? "md" : ""}
                    borderRightRadius={menuItem === menuItems[menuItems.length - 1] ? "md" : ""}
                  >
                    { menuItem }
                  </Box>
                )
              })
            }
          </>
        )
      }
    </>
  )
}

export const MenuItemsSection = ({ currentMenuItem, menuItems, setCurrentMenuItem }: Props) => {
  const { isMobile } = useDeviceContext()
  
  return (
    <HStack pl={isMobile ? 5 : 0} mb={isMobile ? 5 : 0} spacing={0}>
      <MenuSelection currentMenuItem={currentMenuItem} menuItems={menuItems} setCurrentMenuItem={setCurrentMenuItem} />
    </HStack>
  )
}