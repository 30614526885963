import { useState } from "react"
import { FaFire, FaQuestion, FaRegCircle, FaSnowflake } from "react-icons/fa6"
import {
  Icon,
  Text,
  VStack,
} from "@chakra-ui/react"
import { UserPlayerValueType } from "../../../types"

interface Props {
  userPlayerValue?: UserPlayerValueType
  isFilled: boolean
  isLocked: boolean
  handleBoomBustClick?: (value: UserPlayerValueType) => void
}

export const BoomBustBox = ({ userPlayerValue, isFilled, isLocked, handleBoomBustClick }: Props) => {
  const [isHovering, setIsHovering] = useState(false)
  const bgColor = "#323D82"
  const outlineColor = "#82859A"
  
  const getIconByUserPlayerValue = () => {
    if (userPlayerValue === UserPlayerValueType.BOOM) {
      return <Icon as={FaFire} h={20} w={20} color={isHovering || isFilled || isLocked ? "playerValue.boom" : outlineColor} />
    } else if (userPlayerValue === UserPlayerValueType.BUST) {
      return <Icon as={FaSnowflake} h={20} w={20} color={isHovering || isFilled || isLocked ? "playerValue.bust" : outlineColor} />
    } else if (userPlayerValue === UserPlayerValueType.OK) {
      const size = isLocked ? 20 : 30
      return <Icon as={FaRegCircle} h={size} w={size} color={isHovering || isFilled || isLocked ? "playerValue.ok" : outlineColor} />
    } else {
      return <Icon as={FaQuestion} h={20} w={20} color="white" />
    }
  }

  const onBoomBustClick = () => {
    if (isLocked) return
    if (!userPlayerValue) return
    if (handleBoomBustClick) handleBoomBustClick(userPlayerValue)
  }

  const getLabelByUserPlayerValue = (): string => {
    if (userPlayerValue === UserPlayerValueType.BOOM) {
      return "Love"
    } else if (userPlayerValue === UserPlayerValueType.BUST) {
      return "Bust"
    } else if (userPlayerValue === UserPlayerValueType.OK) {
      return "Ok"
    } else {
      return "N/A"
    }
  }

  return (
    <VStack
      borderRadius={10}
      p={10}
      cursor="pointer"
      border={`2px solid ${isHovering || isFilled || isLocked ? bgColor : outlineColor}`}
      bg={ isHovering || isFilled || isLocked ? bgColor : "transparent" }
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onClick={() => onBoomBustClick()}
    >
      <Text fontSize="xs" fontWeight="bold" pb={5} color={isHovering || isFilled || isLocked ? "white" : outlineColor}>
        { getLabelByUserPlayerValue() }
      </Text>
      { getIconByUserPlayerValue() }
    </VStack>
  )
}