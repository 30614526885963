import { useRef, useState } from "react"
import { Box, Spacer, Stack, Text } from "@chakra-ui/react"
import { LootRarity } from "../../types"

interface Props {
  width: string | number
  height?: string | number
  isAnimated: boolean
  url: string
  username?: string
  rarity: LootRarity
}

export const ProfileCard = ({ width, height, isAnimated, url, username, rarity }: Props) => {
  const getClassByRarity = () => {
    switch(rarity) {
      case LootRarity.prize:
        return "card prize"
      case LootRarity.mythic:
        return "card mythic"
      case LootRarity.rare:
        return "card rare pika"
      default:
        return "card eevee"
    }
  }

  const ref = useRef<HTMLDivElement | null>(null)
  const [cardClass, setCardClass] = useState<string>(getClassByRarity())
  const [cardStyle, setCardStyle] = useState<any>({})
  let x: any = null

  const handleMouseMove = (e: React.MouseEvent | React.TouchEvent) => {
    const pos = e.nativeEvent instanceof TouchEvent ? [
      e.nativeEvent.touches[0].clientX,
      e.nativeEvent.touches[0].clientY
    ] : [
      (e as React.MouseEvent<Element, MouseEvent>).nativeEvent.offsetX,
      (e as React.MouseEvent<Element, MouseEvent>).nativeEvent.offsetY
    ] 
    e.preventDefault();
    // math for mouse position
    const l = pos[0];
    const t = pos[1];
    const h = ref.current?.offsetHeight ?? 1
    const w = ref.current?.offsetWidth ?? 1
    const px = Math.abs(Math.floor(100 / w * l)-100);
    const py = Math.abs(Math.floor(100 / h * t)-100);
    const pa = (50-px)+(50-py);
    // math for gradient / background positions
    const lp = (50+(px - 50)/1.5);
    const tp = (50+(py - 50)/1.5);
    const px_spark = (50+(px - 50)/7);
    const py_spark = (50+(py - 50)/7);
    const p_opc = 20+(Math.abs(pa)*1.5);
    // css to apply for active card
    const grad_pos = `background-position: ${lp}% ${tp}%;`
    const sprk_pos = `background-position: ${px_spark}% ${py_spark}%;`
    const opc = `opacity: ${p_opc/100};`
    // need to use a <style> tag for psuedo elements
    const style = {
      "hover:before" : grad_pos,
      "hover:after": `${sprk_pos} ${opc}`
    }

    setCardStyle(style)
    setCardClass(getClassByRarity())
    if (e.nativeEvent instanceof TouchEvent) {
      return false
    }
    clearTimeout(x)
  }

  const handleMouseOut = () => {
    setCardStyle({})
    if (isAnimated) {
      x = setTimeout(function() {
        setCardClass(`${getClassByRarity()} animated`)
      }, 1500)
    }
  }

  return (
    <Box w="100%" bg="transparent" borderTopRadius={10} alignItems="center">
      <Box
        ref={ref}
        key={url}
        w={height}
        h={height}
        className={cardClass}
        style={cardStyle}
        onMouseMove={(e) => handleMouseMove(e)}
        onTouchMove={(e) => handleMouseMove(e)}
        onMouseOut={() => handleMouseOut()}
        alignItems="center"
      >
        <Box w={width} h={width} borderRadius={10} bgPos="center" alignItems="center" bgImage={`url(${url})`} bgSize="cover">
          {
            username && (
              <Stack w="100%" h="100%" bgGradient="linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.9))">
                <Spacer />
                <Text color="white" fontWeight="bold" fontSize="sm" p={5}>
                  { username }
                </Text>
              </Stack>
            )
          }
          {/* <Image w={width} h={width} objectFit="cover" bgPos="center" src={url} /> */}
        </Box>
      </Box>
    </Box>
  )
}