import { Text, useMediaQuery, VStack } from "@chakra-ui/react"
 
const FIRST_PARAGAPH = "Welcome to Armchair GMs, your premier destination for evaluating and forecasting the potential of NFL draft prospects."
const SECOND_PARAGRAPH = "Dive into our platform to assess and rank the promising talent of the 2024 NFL Draft class. Join a community of passionate fans and enthusiasts as you compare your predictions with those of NFL general managers and fellow armchair GMs. Create your own Big Board to curate a list of your top prospects and check out your detailed stats on how accurate your predictions are."
const THIRD_PARAGRAPH = "We believe that every fan deserves a way to express how they feel like their team should be run and assess how they are at determining what their team's roster should look like. There are only 32 general managers throughout the league, but millions of armchair gms at home that care just as much about the success of their team."

export const AboutUs = () => {
  const [isLargerThan62] = useMediaQuery('(min-width: 62em)')
  return (
    <VStack p={10} spacing={10}>
      <Text fontSize={isLargerThan62 ? '5xl' : '4xl'} fontWeight="bold" textAlign="center">
        Think you're a better scout than the NFL GMs?
      </Text>
      <Text fontSize="md" fontWeight="medium" textAlign="center">
        { FIRST_PARAGAPH }
      </Text>
      <Text fontSize="md" fontWeight="medium" textAlign="center">
        { SECOND_PARAGRAPH }
      </Text>
      <Text fontSize="md" fontWeight="medium" textAlign="center">
        { THIRD_PARAGRAPH }
      </Text>
    </VStack>
  )
}