import { useEffect } from "react"
import { ListItem, Stack, Text, UnorderedList } from "@chakra-ui/react"

export const PrivacyPolicyPage = () => {
  useEffect(() => {
    document.title = "Privacy Policy - Armchair GMs"
  }, [])

  return (
    <Stack spacing={5} p={10} bg="white">
        <Text color="black">
            Last Revised: October 25, 2022
        </Text>
        <Text color="black">
            Armchair GMs cares about your privacy. For this reason, we collect and use personal data only as it might be needed for us to deliver to you our world-class products, services, and websites. Your personal data includes information such as:
        </Text>
        <UnorderedList pl={10}>
            <ListItem color="black">
                Name
            </ListItem>
            <ListItem color="black">
                Email Address
            </ListItem>
            <ListItem color="black">
                Address
            </ListItem>
            <ListItem color="black">
                Date of Birth
            </ListItem>
            <ListItem color="black">
                Phone Number
            </ListItem>
            <ListItem color="black">
                Other data that could directly or indirectly identify you
            </ListItem>
        </UnorderedList>
        <Text color="black">
            Our Privacy Policy is intended to describe to you how and what data we collect, and how and why we use your personal data. It also describes options we provide for you to access, update or otherwise take control of your personal data that we process.
        </Text>
        <Text color="black">
            If at any time you have questions about our practices or any of your rights described below, you may reach us at: help@armchairgms.com
        </Text>
        <Text fontWeight="bold" color="black">
            What we collect
        </Text>
        <Text color="black">
            We collect information so that we can provide the best possible experience when you utilize our Services. Much of what you likely consider personal data is collected directly from you when you:
        </Text>
        <UnorderedList pl={10}>
            <ListItem color="black">
                create an account or purchase any of our Services (ex: billing information, including name, address, credit card number, government identification)
            </ListItem>
            <ListItem color="black">
                request assistance from our customer support team (ex: phone number)
            </ListItem>
            <ListItem color="black">
                complete contact forms or request newsletters or other information from us (ex: email); or
            </ListItem>
            <ListItem color="black">
                participate in contests and surveys, apply for a job, or otherwise participate in activities we promote that might require information about you.
            </ListItem>
        </UnorderedList>
        <Text color="black">
            However, we also collect additional information when delivering our Services to you to ensure necessary and optimal performance. These methods of collection may not be as obvious to you, so we wanted to highlight and explain below a bit more about what these might be (as they vary from time to time) and how they work:
        </Text>
        <Text color="black">
            Account related information is collected in association with your use of our Services, such as account number, purchases, when products renew or expire, information requests, and customer service requests and notes or details explaining what you asked for and how we responded.
        </Text>
        <Text color="black">
            Cookies or other tracking technologies such as pixels, and end-user website activity on our website allow us to track your browsing behavior, links clicked, items purchased, your device type, and to collect various data, including analytics, about how you use and interact with our Services. This allows us to provide you with more relevant product offerings, a better experience on our sites, and to collect, analyze and improve the performance of our Services. We may also collect your location (IP address) so that we can personalize our Services.
        </Text>
        <Text color="black">
            Data about Usage of Services is automatically collected when you use and interact with our Services, including metadata, log files, cookie/device IDs and location information. This information includes specific data about your interactions with the features, content and links (including those of third-parties, such as social media plugins) contained within the Services, Internet Protocol (IP) address, browser type and settings, the date and time the Services were used, information about browser configuration and plugins, language preferences and cookie data, information about devices accessing the Services, including type of device, what operating system is used, device settings, application IDs, unique device identifiers and error data, and some of this data collected might be capable of and be used to approximate your location.
        </Text>
        <Text fontWeight="bold" color="black">
            How we use your data
        </Text>
        <Text color="black">
            We strongly believe in both minimizing the data we collect and limiting its use and purpose to only that (1) for which we have been given permission, (2) as necessary to deliver the Services you purchase or interact with, or (3) as we might be required or permitted for legal compliance or other lawful purposes. These uses include:
        </Text>
        <Text color="black">
            Delivering, improving, updating and enhancing the Services we provide to you. We collect various information relating to your purchase, use and/or interactions with our Services. We utilize this information to:
        </Text>
        <UnorderedList pl={5}>
            <ListItem color="black">
                Improve and optimize the operation and performance of our Services
            </ListItem>
            <ListItem color="black">
                Diagnose problems with and identify any security risks, errors, or needed enhancements to the Services
            </ListItem>
            <ListItem color="black">
                Detect and prevent fraud and abuse of our Services and systems
            </ListItem>
            <ListItem color="black">
                Collecting aggregate statistics about use of the Services
            </ListItem>
            <ListItem color="black">
                Understand and analyze how you use our Services and what products and services are most relevant to you
            </ListItem>
        </UnorderedList>
        <Text color="black">
            Often, much of the data collected is aggregated or statistical data about how individuals use our Services, and is not linked to any personal data, but to the extent it is itself personal data, or is linked or linkable to personal data, we treat it accordingly.
        </Text>
        <Text color="black">
            Sharing with trusted third parties. We may share your personal data with affiliated companies within our corporate family, with third parties with which we have partnered to allow you to integrate their services into our own Services, and with trusted third party service providers as necessary for them to perform services on our behalf, such as:
        </Text>
        <UnorderedList pl={5}>
            <ListItem color="black">
                Processing credit card payments
            </ListItem>
            <ListItem color="black">
                Serving advertisements
            </ListItem>
            <ListItem color="black">
                Detect and prevent fraud and abuse of our Services and systems
            </ListItem>
            <ListItem color="black">
                Conducting contests or surveys
            </ListItem>
            <ListItem color="black">
                Performing analysis of our Services and customers demographics
            </ListItem>
            <ListItem color="black">
                Communicating with you, such as by way email or survey delivery
            </ListItem>
            <ListItem color="black">
                Customer relationship management
            </ListItem>
        </UnorderedList>
        <Text color="black">
            We only share your personal data as necessary for any third party to provide the services as requested or as needed on our behalf. These third parties (and any subcontractors) are subject to strict data processing terms and conditions and are prohibited from utilizing, sharing or retaining your personal data for any purpose other than as they have been specifically contracted for (or without your consent). We share hashed identifiers derived from email addresses for the purposes of cross-device tracking for targeted advertising.
        </Text>
        <Text color="black">
            Communicating with you. We may contact you directly or through a third party service provider regarding products or services you have signed up or purchased from us, such as necessary to deliver transactional or service related communications. We may also contact you with offers for additional services we think you’ll find valuable if you give us consent, or where allowed based upon legitimate interests. You don’t need to provide consent as a condition to purchase our goods or services. These contacts may include:
        </Text>
        <UnorderedList pl={5}>
            <ListItem color="black">
                Email
            </ListItem>
            <ListItem color="black">
                Telephone calls
            </ListItem>
            <ListItem color="black">
                Hashed identifiers derived from email addresses
            </ListItem>
        </UnorderedList>
        <Text color="black">
            If we collect information from you in connection with a co-branded offer, it will be clear at the point of collection who is collecting the information and whose privacy policy applies.
        </Text>
        <Text color="black">
            If you believe that anyone has provided us with your personal information and you would like to request that it be removed from our database, please contact us at help@armchairgms.com
        </Text>
        <Text color="black">
            Transfer of personal data abroad. If you utilize our Services from a country other than the country where our servers are located, your communications with us may result in transferring your personal data across international borders.
        </Text>
        <Text color="black">
            Compliance with legal, regulatory and law enforcement requests. We cooperate with government and law enforcement officials and private parties to enforce and comply with the law. We will disclose any information about you to government or law enforcement officials or private parties as we, in our sole discretion, believe necessary or appropriate to respond to claims and legal process (such as subpoena requests), to protect our property and rights or the property and rights of a third party, to protect the safety of the public or any person, or to prevent or stop activity we consider to be illegal or unethical.
        </Text>
        <Text color="black">
            Website analytics. We use multiple web analytics tools provided by service partners such as Google Analytics to collect information about how you interact with our website, including what pages you visit, what site you visited prior to visiting our website, how much time you spend on each page, what operating system and web browser you use and network and IP information. We use the information provided by these tools to improve our Services. These tools place persistent cookies in your browser to identify you as a unique user the next time you visit our website. Each cookie cannot be used by anyone other than the service provider. The information collected from the cookie may be transmitted to and stored by these service partners on servers in a country other than the country in which you reside. Though information collected does not include personal data such as name, address, billing information, etc., the information collected is used and shared by these service providers in accordance with their individual privacy policies. You can control the technologies we use by managing your settings through by utilizing settings in your browser or third-party tools, such as Disconnect, Ghostery and others.
        </Text>
        <Text color="black">
            Targeted advertisements. Targeted ads or interest-based offers may be presented to you based on your activities on our webpages, and other websites, and based on the products you currently own. These offers will display as varying product banners presented to you while browsing. We also partner with third parties to manage our advertising on our webpages and other websites. Our third party partners may use technologies such as cookies to gather information about such activities in order to provide you with advertising based upon your browsing activities and interests, and to measure advertising effectiveness. Third parties may place cookies on their browsers for targeted advertising purposes.
        </Text>
        <Text color="black">
            Third-party websites. Our website contains links to third-party websites. We are not responsible for the privacy practices or the content of third-party sites. Please read the privacy policy of any website you visit.
        </Text>
        <Text fontWeight="bold" color="black">
            How we secure, store and retain your data
        </Text>
        <Text color="black">
            We follow generally accepted standards to store and protect the personal data we collect, both during transmission and once received and stored, including utilization of encryption where appropriate.
        </Text>
        <Text color="black">
            We retain personal data only for as long as necessary to provide the Services you have requested and thereafter for a variety of legitimate legal or business purposes. These might include retention periods mandated by law, contract or similar obligations applicable to our business operations; for preserving, resolving, defending or enforcing our legal/contractual rights; or needed to maintain adequate and accurate business and financial records.
        </Text>
        <Text color="black">
            If you have any questions about the security or retention of your personal data, you can contact us at help@armchairgms.com
        </Text>
        <Text fontWeight="bold" color="black">
            Age restrictions
        </Text>
        <Text color="black">
            Our Services are available for purchase only for those over the age of 18. Our Services are not targeted to, intended to be consumed by or designed to entice individuals under the age of 18. If you know of or have reason to believe anyone under the age of 18 has provided us with any personal data, please contact us.
        </Text>
        <Text fontWeight="bold" color="black">
            Changes in our Privacy Policy
        </Text>
        <Text color="black">
            We reserve the right to modify this Privacy Policy at any time. If we decide to change our Privacy Policy, we will post those changes to this Privacy Policy and any other places we deem appropriate, so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we disclose it.
        </Text>
        <Text fontWeight="bold" color="black">
            Contact Us
        </Text>
        <Text color="black">
            If you have any questions, concerns or complaints about our Privacy Policy, our practices or our Services, you may contact us at help@armchairgms.com
        </Text>
    </Stack>
  )
}