import { LeaderboardMenuItemType, LeaderboardType, LootRarity, NavOption, Team } from "../types"

export const NAV_OPTIONS = [
    // NavOption.HOME,
    // NavOption.INVITATIONAL,
    NavOption.PLAYERS,
    // NavOption.LEADERBOARD,
    NavOption.PROFILE,
    // NavOption.BIG_BOARD,
    NavOption.STATS,
]

export const NavOptionsDict = {
  home: "",
  team: "team",
  teams: "teams",
  account: "account",
  big_board: "big-board",
  demo: "demo",
  invitational: "invitational",
  my_badges: "badges/edit",
  player: "player",
  players: "players",
  leaderboard: "leaderboard",
  rewards: "rewards",
  profile: "profile",
  my_stats: "my-stats",
  loot: "loot",
  settings: "settings",
  film: "film",
  stats: "stats",
}

export const LEADERBOARD_MENU_ITEMS: LeaderboardType[] = [
  {
    type: LeaderboardMenuItemType.PLAYERS,
    label: "Players"
  }
]

export const RATING_REALLY_UNDERRATED = 10
export const RATING_UNDERRATED = 5
export const RATING_OVERRATED = -5
export const RATING_REALLY_OVERRATED = -10

export const getBgByRarity = (rarity: LootRarity) => {
  switch(rarity) {
    case LootRarity.mythic:
      return "linear-gradient(to right, #00e7ff, #ff00e7)"
    case LootRarity.rare:
      return "linear-gradient(to right, #54a29e, #e7d7ba)"
    case LootRarity.prize:
      return "linear-gradient(to right, #685625, #F8E68A)"
    default:
      return "linear-gradient(to right, #efb2fb, #acc6f8)"
  }
}

export const getBoxShadowByRarity = (rarity: LootRarity) => {
  switch(rarity) {
    case LootRarity.mythic:
      return `-5px -5px 5px -5px #00e7ff, 5px 5px 5px -5px #ff00e7, -7px -7px 10px -5px transparent, 7px 7px 10px -5px transparent, 0 0 5px 0px rgba(255,255,255,0), 0 55px 35px -20px rgba(0, 0, 0, 0.5)`
    case LootRarity.rare:
      return `-5px -5px 5px -5px #54a29e, 5px 5px 5px -5px #a79d66, -7px -7px 10px -5px transparent, 7px 7px 10px -5px transparent, 0 0 5px 0px rgba(255,255,255,0), 0 55px 35px -20px rgba(0, 0, 0, 0.5)`
    case LootRarity.prize:
      return `-5px -5px 5px -5px #685625, 5px 5px 5px -5px #F8E68A, -7px -7px 10px -5px transparent, 7px 7px 10px -5px transparent, 0 0 5px 0px rgba(255,255,255,0), 0 55px 35px -20px rgba(0, 0, 0, 0.5)`
    default:
      return `-5px -5px 5px -5px #efb2fb, 5px 5px 5px -5px #acc6f8, -7px -7px 10px -5px transparent, 7px 7px 10px -5px transparent, 0 0 5px 0px rgba(255,255,255,0), 0 55px 35px -20px rgba(0, 0, 0, 0.5)`
  }
}

export const toTitleCase = (str: string) => {
  return str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  )
}

export const MENU_ITEM_BIG_BOARD = "Big Board"
export const MENU_ITEM_BOOM_BUST = "Boom / Bust"
export const MENU_ITEM_MOCK_DRAFTS = "Mock Drafts"

export const MenuItems = [
  MENU_ITEM_BIG_BOARD,
  MENU_ITEM_BOOM_BUST,
  // MENU_ITEM_MOCK_DRAFTS,
]