import axios from "axios"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Box, Button, Input, InputGroup, InputRightElement, Text, useMediaQuery, VStack, Spacer } from "@chakra-ui/react"
import { Navigation } from "./ui"

export const ResetPasswordPage = () => {
  const navigate = useNavigate()
  const params = useParams()
  const [isLargerThan62] = useMediaQuery('(min-width: 62em)')
  const [isExpired, setIsExpired] = useState(false)
  const [newPassword, setNewPassword] = useState("")
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showVerifyPassword, setShowVerifyPassword] = useState(false)
  const [isPasswordTheSame, setIsPasswordTheSame] = useState(false)

  useEffect(() => {
    const validateRefreshToken = async () => {
      const { token } = params
      if (token) {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/validate-reset-token`, { resetToken: token })
        const { data } = res
        if (!data.success) {
          setIsExpired(true)
        }
      }
    }

    document.title = "Reset Password - Armchair GMs"
    validateRefreshToken()
  }, [])

  const handleContinueClick = async () => {
    const { token } = params
    await axios.post(`${process.env.REACT_APP_API_URL}/auth/reset-password`, { newPassword, resetToken: token })
  }

  const handleSetConfirmPassword = (pw: string) => {
    if (pw === newPassword && pw !== "" && newPassword !== "") {
      setIsPasswordTheSame(true)
    } else {
      setIsPasswordTheSame(false)
    }
  }

  return (
    <Box bg="#1A1A1E" h="100vh">
      <Navigation />
      <VStack
        alignItems="center"
        pt={10}
        px={isLargerThan62 ? '16' : '6'}
        spacing={3}
      >
        <Text fontSize={isLargerThan62 ? "larger" : "large"} fontWeight="bold" color="white">
          Set New Password
        </Text>
        <Text fontSize="medium" fontWeight="medium" pb={10} color="white">
          Set a new password for your account
        </Text>
        {
          isExpired ? (
            <>
              <Text color="red">
                This token is no longer valid. Links expire after 1 hour.
              </Text>
              <Button
                size="md"
                color="white"
                bgGradient="linear-gradient(to right, #EB46A6, #E20FF5)"
                onClick={() => navigate(`/account-recovery`)}
              >
                Go Back
              </Button>
            </>
          ) : (
            <>
              <InputGroup size="md" mt={5} w="40%">
                <Input
                  pr="4.5rem"
                  variant="filled"
                  fontWeight="bold"
                  color="brand.50"
                  focusBorderColor="brand.50"
                  type={showNewPassword ? "text" : "password"}
                  placeholder="New Password"
                  onChange={(event) => setNewPassword(event.target.value)}
                />
                <InputRightElement width="4.5rem">
                  <Button h="1.75rem" size="sm" color="white" bgGradient="linear-gradient(to right, #EB46A6, #E20FF5)" onClick={() => setShowNewPassword(!showNewPassword)}>
                    {showNewPassword ? "Hide" : "Show"}
                  </Button>
                </InputRightElement>
              </InputGroup>
              <InputGroup size="md" mt={5} w="40%">
                <Input
                  pr="4.5rem"
                  variant="filled"
                  fontWeight="bold"
                  color="brand.50"
                  focusBorderColor="brand.50"
                  type={showVerifyPassword ? "text" : "password"}
                  placeholder="Verify Password"
                  onChange={(event) => handleSetConfirmPassword(event.target.value)}
                />
                <InputRightElement width="4.5rem">
                  <Button h="1.75rem" size="sm" color="white" bgGradient="linear-gradient(to right, #EB46A6, #E20FF5)" onClick={() => setShowVerifyPassword(!showVerifyPassword)}>
                    {showVerifyPassword ? "Hide" : "Show"}
                  </Button>
                </InputRightElement>
              </InputGroup>
              <Button
                size="md"
                color="white"
                bgGradient="linear-gradient(to right, #EB46A6, #E20FF5)"
                disabled={!isPasswordTheSame}
                onClick={handleContinueClick}
              >
                Continue
              </Button>
            </>
          )
        }
        <Spacer />
      </VStack>
    </Box>
  )
}