import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { HStack, Image, Spacer, Stack, Text, VStack } from "@chakra-ui/react"
import { ProfileCard, Section } from "../ui"
import { getBgByRarity } from "../../constants"
import { useDeviceContext } from "../../hooks"
import { LootRarity, User } from "../../types"

export const LeaderboardComponent = () => {
  const { isMobile, screenWidth } = useDeviceContext()
  const navigate = useNavigate()
  const size = screenWidth * 0.65 * 0.25
  const goldGradient = "linear-gradient(to right, #705E2B, #F2E085)"
  const silverGradient = "linear-gradient(to right, #C0C0C0, #D3D3D3)"
  const bronzeGradient = "linear-gradient(to right, #CD7F32, #BE7023)"
  const [leaderboard, setLeaderboard] = useState<User[]>([])

  const getGradientByIndex = (index: number) => {
    switch(index) {
      case 0:
        return goldGradient
      case 1:
        return silverGradient
      case 2:
        return bronzeGradient
      default:
        return "transparent"
    }
  }

  return (
    <Stack w="100%" pt={isMobile ? 55 : 0}>
      <Text fontSize="2xl" fontWeight="bold">
        Leaderboard
      </Text>
      <HStack w="100%" p={5} spacing={5} mb={10}>
        {
          leaderboard.slice(0, 3).map((user, index) => {
            return (
              <VStack
                w="33%"
                p="1px"
                borderRadius={10}
                bgGradient={ getGradientByIndex(index) }
                cursor="pointer"
                onClick={() => navigate(`/profile/${user.username}`)}
              >
                <Section alignItems="center" p={1}>
                  <VStack p={5} borderRadius={10} bg="transparent">
                    <VStack alignItems="center" width="100%" borderRadius={10} bg="transparent">
                      <ProfileCard width={size} height={size} isAnimated={true} url={user.profilePicture ?? ""} rarity={LootRarity.prize} />
                    </VStack>
                  </VStack>
                  <HStack w="100%" bgGradient={ getGradientByIndex(index) }>
                    <Spacer />
                    <Text fontWeight="bold" color="black">
                      { user.username }
                    </Text>
                    <Spacer />
                  </HStack>
                  <Image mt={2} mb={5} src={"https://armchairgms-images.s3.amazonaws.com/5_112.png"} w={50} />
                </Section>
              </VStack>
            )
          })
        }
      </HStack>
      {
        leaderboard.slice(3).map((user, index) => {
          return (
            <Section mb={5} h={100.48} cursor="pointer" onClick={() => navigate(`/profile/${user.username}`)}>
              <HStack w="100%">
                <Text pl={5} pr={5} fontWeight="bold" fontSize="xl">
                  { index + 4 }.
                </Text>
                <VStack spacing={0} p={0.48} w="5%" h={99.62} bgGradient={ getBgByRarity(LootRarity.mythic) }>
                  <Image src={user.profilePicture} pt={index === 0 ? 0.2 : 0} w="100%" h={99} objectFit="cover" />
                </VStack>
                <Text pl={5} fontWeight="bold" fontSize="xl">
                  { user.username }
                </Text>
                <Spacer />
                <Image mr={5} src={"https://armchairgms-images.s3.amazonaws.com/3_112.png"} w={50} />
              </HStack>
            </Section>
          )
        })
      }
    </Stack>
  )
}