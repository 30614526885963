import axios from "axios"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Box, HStack, Stack, Text, VStack } from "@chakra-ui/react"
import { BoomBustPlayerItem } from "./"
import { GradientButton, NflDraftYearSelect, PositionsSelect, SearchBar } from "../../ui"
import { NflDraft, Position, UserPlayer } from "../../../types"

interface Props {
  currentNflDraft: NflDraft | null
  isUserProfile: boolean
  nflDrafts: NflDraft[]
  userPlayers: UserPlayer[]
  handleNflDraftChange: (value: string) => void
}

export const BoomBustSection = ({
  currentNflDraft,
  isUserProfile,
  nflDrafts,
  userPlayers,
  handleNflDraftChange,
}: Props) => {
  const navigate = useNavigate()
  const [searchPlayerText, setSearchPlayerText] = useState<string>("")
  const [positions, setPositions] = useState<Position[]>([])
  const [filteredUserPlayers, setFilteredUserPlayers] = useState<UserPlayer[]>([])
  const [currentPosition, setCurrentPosition] = useState("All Positions")

  useEffect(() => {
    const fetchPositions = async () => {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/positions/all`)
      const { data } = res
      if (data.success) {
        const { positions } = data
        const _positions = [{ id: 999, name: "All Positions" }, ...positions]
        setPositions(_positions)
      }
    }

    fetchPositions()
  }, [])

  useEffect(() => {
    setFilteredUserPlayers(userPlayers)
  }, [userPlayers])

  const handlePositionChange = (position: string) => {
    setCurrentPosition(position)
    if (position === "All Positions") {
      setFilteredUserPlayers(userPlayers)
    } else {
      const _filteredUserPlayers = userPlayers.filter((userPlayer) => {
        return userPlayer.player.position.name === position
      })
      setFilteredUserPlayers(_filteredUserPlayers)
    } 
  }

  const searchPlayers = (name: string) => {
    setSearchPlayerText(name)
    if (name.length === 0) {
      handlePositionChange(currentPosition)
    } else if (name.length > 0) {
      const searchedPlayers = userPlayers.filter(userPlayer => { return `${userPlayer.player.firstName} ${userPlayer.player.lastName}`.toLowerCase().includes(name.toLowerCase()) })
      if (currentPosition !== "All Positions") {
        const _filteredPlayers = searchedPlayers.filter((p) => {
          return p.player.position.name === currentPosition
        })
        setFilteredUserPlayers(_filteredPlayers)
      } else {
        setFilteredUserPlayers(searchedPlayers)
      }
    }
  }

  return (
    <Stack p={5}>
      <Text fontSize="4xl" fontWeight="bold" pt={5} pb={5}>Boom / Bust</Text>
      <Stack pb={10}>
        <Text fontWeight="bold">NFL Draft Year</Text>
        <NflDraftYearSelect
          nflDraft={currentNflDraft ? currentNflDraft.year.toString() : undefined}
          nflDrafts={nflDrafts}
          handleNflDraftChange={handleNflDraftChange}
        />
        <HStack w="100%" spacing={5} pt={5}>
          <SearchBar placeHolder="Search Players" text={searchPlayerText} handleTextChange={searchPlayers} />
          <PositionsSelect positions={positions} handlePositionChange={handlePositionChange} hideHeader/>
        </HStack>
      </Stack>
      {
         filteredUserPlayers.length === 0 && (
          <VStack>
            <Text fontSize="2xl" pt={10} pb={5} fontWeight="bold" textAlign="center">
              Nothing here yet
            </Text>
            {
              isUserProfile && (
                <GradientButton onClick={() => navigate(`/players`)}>
                  Click here to get started
                </GradientButton>
              )
            }
          </VStack>
        )
      }
      <Stack spacing={0} borderRadius={10}>
        {
          filteredUserPlayers.map((userPlayer, index) => {
            return (
              <Box key={index}>
                <BoomBustPlayerItem
                  index={index}
                  isEnd={index === filteredUserPlayers.length - 1}
                  userPlayer={userPlayer}
                />
              </Box>
            )
          })
        }
      </Stack>
    </Stack>
  )
}