import axios from "axios"
import { useEffect, useMemo, useState } from "react"
import { Main } from "./components/Main"
import { AllNflDraftsContext, NflDraftContext, TeamsContext, UserContext, getSessionCookie } from "./hooks"
import { Me, NflDraft, Team } from "./types"
import "./App.scss"

function App() {
  const [user, setUser] = useState<Me | null>(getSessionCookie())
  const [currentNflDraft, setCurrentNflDraft] = useState<NflDraft | null>(null)
  const [allNflDrafts, setAllNflDrafts] = useState<NflDraft[]>([])
  const [teams, setTeams] = useState<Team[]>([])

  useEffect(() => {
  const fetchCurrentNflDraftAndMe = async () => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/me`, {}, { withCredentials: true })
      const { data } = res
      if (data.success) {
        setUser(data.user)
      }
    } catch(e) {
      console.log(e)
    }
  }

    const fetchCurrentNflDraft = async () => {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/draft/current`)
      const { data } = res
      if (data.success) {
        setCurrentNflDraft(data.nflDraft)
        return data.nflDraft
      }
    }

    const fetchTeams = async () => {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/teams/all`)
      const { data } = res
      if (data.success) {
        setTeams(data.teams)
      }
    }

    const fetchAllNflDrafts = async () => {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/draft/all`)
      const { data } = res
      if (data.success) {
        const { nflDrafts } = data
        setAllNflDrafts(nflDrafts)
      }
    }

    if (!user) {
      fetchCurrentNflDraftAndMe()
    }

    if (!currentNflDraft) {
      fetchCurrentNflDraft()
    }

    if (teams.length === 0) {
      fetchTeams()
    }

    if (allNflDrafts.length === 0) {
      fetchAllNflDrafts()
    }
  }, [])

  const userValue = { user, setUser }

  const nflDraftValue = useMemo(
    () => (currentNflDraft),
    [currentNflDraft]
  )

  const teamsValue = useMemo(
  () => (teams),
  [teams]
  )

  const allNflDraftsValue = useMemo(
    () => (allNflDrafts),
    [allNflDrafts]
  )

  return (
    <AllNflDraftsContext.Provider value={allNflDraftsValue}>
      <UserContext.Provider value={userValue}>
        <NflDraftContext.Provider value={nflDraftValue}>
          <TeamsContext.Provider value={teamsValue}>
            <Main />
          </TeamsContext.Provider>
        </NflDraftContext.Provider>
      </UserContext.Provider>
    </AllNflDraftsContext.Provider>
  )
}

export default App
