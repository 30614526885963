import { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { Box, HStack, Image, Spacer, Stack, Text } from "@chakra-ui/react"
import { Player, User } from "../../../types"

interface Props {
  isVisible: boolean
  players: Player[]
  users: User[]
}

const SearchItem = ({ player, user }: { player?: Player, user?: User }) => {
  const navigate = useNavigate()
  const [isHovering, setIsHovering] = useState(false)

  if (player) {
    return (
      <HStack
        p={2}
        borderRadius={10}
        bg={isHovering ? "gray.100" : "white"}
        cursor="pointer"
        onClick={() => navigate(`/player/${player.id}`)}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <Stack>
          <Text fontSize="sm" fontWeight="bold" color="black" as={Link}>
            { `${player.firstName} ${player.lastName}` }
          </Text>
          <Text fontSize="xs" fontWeight="bold" color="gray">
            { player.school.name }
          </Text>
        </Stack>
        <Spacer />
        <Text fontSize="xs" fontWeight="bold" color="black">
          { player.position.name }
        </Text>
      </HStack>
    )
  } else if (user) {
    return (
      <Box
        borderRadius={10}
        bg={isHovering ? "gray.100" : "white"}
        cursor="pointer"
        onClick={() => navigate(`/profile/${user.username}`)}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <HStack p={2}>
          <Image src={user.profilePicture} w="30px" h="30px" borderRadius={10} />
          <Text fontSize="sm" fontWeight="bold" color="black" pl={2}>
            { user.username }
          </Text>
        </HStack>
      </Box>
    )
  } else {
    return (
      <></>
    )
  }
}

export const SearchDropdown = ({ players, users, isVisible }: Props) => {
  return (
    <Stack
      w="90%"
      maxH="400px"
      p={5}
      borderRadius={10}
      bg="white"
      position="absolute"
      top="130px"
      overflowY="scroll"
      zIndex={100}
      visibility={ isVisible ? "visible" : "hidden" }
    >
      {
        players && players.length > 0 && (
          <Stack pb={5}>
            <Text fontSize="xs" color="gray" fontWeight="bold">
              PLAYERS
            </Text>
            <Stack>
              {
                players.slice(0, 4).map(player => {
                  return (
                    <SearchItem key={player.id} player={player} />
                  )
                })
              }
            </Stack>
          </Stack>
        )
      }
      {
        users.length > 0 && (
          <>
            <Text fontSize="xs" color="gray" fontWeight="bold">
              ACCOUNTS
            </Text>
            {
              users.slice(0, 4).map(user => {
                return (
                  <SearchItem key={user.username} user={user} />
                )
              })
            }
          </>
        )
      }
    </Stack>
  )
}