import axios from "axios"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { HStack, Select, Stack, Text, VStack } from "@chakra-ui/react"
import { ComparisonBox, DetailedStatsModal, PlayerStatsItem, SummaryBox, YearSelect } from "./"
import { GradientButton, PositionsSelect, SearchBar } from "../../ui"
import { useCurrentNflContext, useUserContext } from "../../../hooks"
import { NflDraft, Position, UserStats } from "../../../types"

export const BigBoardStatsComponent = () => {
  const ORDER_BY_VALUES = ["Your Projection", "How They Performed", "Your Grade"]

  const navigate = useNavigate()
  const currentNflDraft = useCurrentNflContext()
  const { user } = useUserContext()
  const [seasons, setSeasons] = useState<number[]>([])
  const [currentYear, setCurrentYear] = useState<number | null>(null)
  const [hasStats, setHasStats] = useState<boolean>(false)
  const [userStats, setUserStats] = useState<UserStats[]>([])
  const [filteredUserStats, setFilteredUserStats] = useState<UserStats[]>([])
  const [rank, setRank] = useState<number | null>(null)
  const [teamRank, setTeamRank] = useState<number | null>(null)
  const [averageRank, setAverageRank] = useState<number | null>(null)
  const [isDetailedStatsOpen, setIsDetailedStatsOpen] = useState(false)
  const [currentStats, setCurrentStats] = useState<UserStats | null>(null)
  const [searchPlayerText, setSearchPlayerText] = useState<string>("")
  const [currentPosition, setCurrentPosition] = useState("All Positions")
  const [positions, setPositions] = useState<Position[]>([])
  const [orderBy, setOrderBy] = useState(ORDER_BY_VALUES[0])
  const [filterYears, setFilterYears] = useState<string[]>([])
  const [currentFilterYear, setCurrentFilterYear] = useState<string>("Any")

  useEffect(() => {
    const fetchCompletedSeasons = async () => {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/draft/completed`)
      const { data } = res
      if (data.success) {
        const { nflDrafts } = data
        const seasons = nflDrafts.map((nflDraft: NflDraft) => { return nflDraft.year })
        const _filterYears = ["Any", ...seasons.map((year: number) => { return year.toString() })]
        setSeasons(seasons)
        setFilterYears(_filterYears)
        setCurrentYear(seasons[0])
      }
    }

    const fetchPositions = async () => {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/positions/all`)
      const { data } = res
      if (data.success) {
        const { positions } = data
        const _positions = [{ id: 999, name: "All Positions" }, ...positions]
        setPositions(_positions)
        setCurrentPosition("All Positions")
      }
    }

    fetchCompletedSeasons()
    fetchPositions()
  }, [])

  useEffect(() => {
    const checkUserStats = async (userId: number) => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/ratings/check`, { userId })
        const { data } = res
        if (data.success) {
          const { result } = data
          setHasStats(result)
        }
      } catch(e) {
        console.log(e)
      }
    }

    if (user) {
      checkUserStats(user.id)
    }
  }, [user])

  useEffect(() => {
    const fetchUserStatsByUserId = async (userId: number, year: number) => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/ratings/stats`, { userId, year })
        const { data } = res
        if (data.success) {
          const { stats, rank, teamRank, averageRank } = data
          setRank(rank)
          setUserStats(stats)
          const _filteredUserStats = orderUserStats(filterUserStats({ userStats: stats, year: currentFilterYear, position: currentPosition }), orderBy)
          setFilteredUserStats(_filteredUserStats)
          setTeamRank(teamRank)
          setAverageRank(averageRank)
        }
      } catch(e) {
        console.log(e)
      }
    }

    if (user && currentYear && hasStats) {
      fetchUserStatsByUserId(user.id, currentYear)
    }
  }, [user, currentYear, hasStats])

  const handleYearChange = (yearStr: string) => {
    const year = parseInt(yearStr)
    setCurrentYear(year)
  }

  const handleOpenDetailedStats = (stats: UserStats) => {
    setCurrentStats(stats)
    setIsDetailedStatsOpen(true)
  }

  const searchPlayers = (name: string) => {
    setSearchPlayerText(name)
    if (name.length === 0) {
      handlePositionChange(currentPosition)
    } else if (name.length > 0) {
      const searchedUserStats = userStats.filter(userStat => { return `${userStat.player.firstName} ${userStat.player.lastName}`.toLowerCase().includes(name.toLowerCase()) })
      if (currentPosition !== "All Positions") {
        const _filteredUserStats = searchedUserStats.filter((userStat) => {
          return userStat.player.position.name === currentPosition
        })
        setFilteredUserStats(_filteredUserStats)
      } else {
        setFilteredUserStats(searchedUserStats)
      }
    }
  }

  const orderUserStats = (userStats: UserStats[], value: string): UserStats[] => {
    if (value === ORDER_BY_VALUES[0]) {
      return userStats.sort((a, b) => { return a.rating.userRankMin - b.rating.userRankMin })
    } else if (value === ORDER_BY_VALUES[1]) {
      return userStats.sort((a, b) => { return b.score - a.score })
    } else if (value === ORDER_BY_VALUES[2]) {
      return userStats.sort((a, b) => { return a.userDelta - b.userDelta })
    }
    return userStats
  }

  const filterUserStatsByYear = (userStats: UserStats[], year: string): UserStats[] => {
    if (year === "Any") {
      return userStats
    } else {
      const _year = parseInt(year)
      return userStats.filter(userStat => { return userStat.player.nflDraft?.year === _year })
    }
  }

  const filterUserStatsByPosition = (userStats: UserStats[], position: string): UserStats[] => {
    if (position === "All Positions") {
      return userStats
    } else {
      return userStats.filter(userStat => { return userStat.player.position.name === position })
    }
  }

  const filterUserStats = ({ userStats, year, position }: {userStats: UserStats[], year: string, position: string}): UserStats[] => {
    const _filteredUserStats = filterUserStatsByYear(userStats, year)
    return filterUserStatsByPosition(_filteredUserStats, position)
  }

  const handlePositionChange = (position: string) => {
    setCurrentPosition(position)
    const _filteredUserStats = filterUserStats({ userStats, year: currentFilterYear, position: position })
    setFilteredUserStats(orderUserStats(_filteredUserStats, orderBy))
  }

  const handleOrderByChange = (value: string) => {
    setOrderBy(value)
    const _filteredUserStats = orderUserStats([...filteredUserStats], value)
    setFilteredUserStats(_filteredUserStats)
  }

  const handleDraftedYearChange = (value: string) => {
    setCurrentFilterYear(value)
    const _filteredUserStats = filterUserStats({ userStats, year: value, position: currentPosition })
    setFilteredUserStats(orderUserStats(_filteredUserStats, orderBy))
  }

  return (
    <>
      <Stack h="100%" w="100%" pb={10}>
        <Text fontWeight="bold" fontSize="sm">NFL Season</Text>
        <YearSelect year={currentYear} years={seasons} handleYearChange={handleYearChange} />
        <HStack spacing={5} pt={5}>
          <SummaryBox rank={rank} title="Your Overall Grade" />
          <ComparisonBox rank1={rank} rank2={teamRank} title="You vs NFL GMs" />
          <ComparisonBox rank1={rank} rank2={averageRank} title="You vs Armchair GMs" />
        </HStack>
        <HStack w="100%" pt={10} pb={5}>
          <Select
            variant="filled"
            bg="white"
            color="brand.50"
            fontWeight="bold"
            onChange={(event) => handleOrderByChange(event.target.value)}
          >
            {
              ORDER_BY_VALUES.map(orderBy => {
                return (
                  <option key={orderBy} value={orderBy}>{ `Order By: ${orderBy}` }</option>            
                )
              })
            }
          </Select>
          <Select
            variant="filled"
            bg="white"
            color="brand.50"
            fontWeight="bold"
            onChange={(event) => handleDraftedYearChange(event.target.value)}
          >
            {
              filterYears.map(year => {
                return (
                  <option key={year} value={year}>{ `Drafted: ${year}` }</option>            
                )
              })
            }
          </Select>
          <PositionsSelect positions={positions} handlePositionChange={handlePositionChange} hideHeader />
        </HStack>
        <SearchBar placeHolder="Search Players" text={searchPlayerText} handleTextChange={searchPlayers} />
        {
          filteredUserStats.length === 0 ? (
            <VStack w="100%" pt={20} pb={20} spacing={5}>
              <Text fontWeight="extrabold" fontSize="2xl">
                No Players Stats Found ... Yet
              </Text>
              {
                currentNflDraft && currentNflDraft.year === currentYear && (
                  <GradientButton onClick={() => navigate("/")}>
                    Click here to start scouting
                  </GradientButton>
                )
              }
            </VStack>
          ) : (
            <Stack w="100%" spacing={0} pt={5} pb={20}>
              {
                filteredUserStats.map((stats, index) => {
                  return (
                    <PlayerStatsItem
                      key={index}
                      index={index}
                      isEnd={index === userStats.length - 1}
                      stats={stats}
                      handleOpenDetailedStats={handleOpenDetailedStats}
                    />
                  )
                })
              }
            </Stack>
          )
        }
      </Stack>
      {
        currentStats && (
          <DetailedStatsModal isOpen={isDetailedStatsOpen} onClose={() => setIsDetailedStatsOpen(false)} stats={currentStats} />
        )
      }
    </>
  )
}