import axios from "axios"
import { useEffect, useState } from "react"
import { Box, HStack, Input, Spacer, Text, Textarea, VStack, useColorModeValue, useToast } from "@chakra-ui/react"
import { Footer, Navigation } from "./components"
import { GradientButton } from "../ui"

export const ContactUsPage = () => {
  const toast = useToast()
  const bg = useColorModeValue('light.100', 'dark.100')
  
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")

  useEffect(() => {
    document.title = "Contact Us - Armchair GMs"
  }, [])

  const sendEmail = async () => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/contact/send`, { email, message })
      const { data } = res
      if (data.success) {
        toast({
          title: `Sent! We'll get back to you soon!`,
          status: "success",
          duration: 4000,
          isClosable: true,
        })
      } else {
        toast({
          title: "Error sending email",
          status: "error",
          duration: 4000,
          isClosable: true,
        })
      }
    } catch (error) {
      toast({
        title: "Error sending email",
        status: "error",
        duration: 4000,
        isClosable: true,
      })
    }
  }

  return (
    <VStack bg={bg} h="100%">
      <Navigation />
      <HStack w="100%" pt={20}>
        <Spacer />
        <Box w="30%">
          <Text fontWeight="extrabold" fontSize="5xl" textAlign="center">
            Contact Us
          </Text>
          <Text fontSize="md" textAlign="center" pt={5}>
            Get in touch with the our team and we'll get back to you and help as soon as we can!
          </Text>
        </Box>
        <Spacer />
      </HStack>
      <VStack w="30%" pt={20} pb={20} spacing={5}>
        <Input
          variant="filled"
          fontWeight="bold"
          bg="#37438E"
          _placeholder={{ color: "white" }}
          color="brand.50"
          focusBorderColor="brand.50"
          placeholder="Your Email"
          onChange={(event: any) => setEmail(event.target.value)}
        />
        <Textarea
          h={200}
          variant="filled"
          fontWeight="bold"
          bg="#37438E"
          _placeholder={{ color: "white" }}
          color="brand.50"
          focusBorderColor="brand.50"
          placeholder="Your Message"
          onChange={(event: any) => setMessage(event.target.value)}
        />
        <GradientButton onClick={() => sendEmail()}>
          Send Message
        </GradientButton>
      </VStack>
      <Footer />
    </VStack>
  )
}