import React, { useContext } from 'react'
import Cookies from "js-cookie"
import { Me } from '../types'

const ME = "me"

export const removeSessionCookie = () => {
  Cookies.remove(ME)
}

export const setSessionCookie = (me: Me) => {
  Cookies.remove(ME)
  Cookies.set(ME, JSON.stringify(me), { expires: 1/24 })
}

export const getSessionCookie: any = () => {
  const cookie = Cookies.get(ME)
  if (cookie) {
    return JSON.parse(cookie)
  } else {
    return null
  }
}

export const getUser = async () => {
  const cookie = Cookies.get(ME)
  if (cookie) {
    return await JSON.parse(cookie) as Me
  } else {
    return null
  }
}

export const getIsLoggedIn = () => {
  return Cookies.get(ME) !== undefined
}

export const UserContext = React.createContext<{ user: Me | null, setUser: (user: Me) => void }>({ user: null, setUser: () => {} })

export const useUserContext = () => {
  return useContext(UserContext)
}