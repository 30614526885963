import axios from "axios"
import { useEffect, useState } from "react"
import {
  Grid,
  HStack,
  Spacer,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react"
import { ArmchairGMsInvitationalModal, SelectPlayerItem, SelectPlayerModal } from "./ui"
import { ALL_ROUNDS, ALL_ROUNDS_AFTER_FIRST, ALL_ROUNDS_AFTER_THIRD } from "./utils"
import { useCurrentNflContext, useUserContext } from "../../hooks"
import { NflDraftSelection, UserPlayerSelection } from "../../types"
import { OutlineButton } from "../ui"

export const CompetitionComponent = () => {
  const { user } = useUserContext()
  const currentNflDraft = useCurrentNflContext()

  const [isHowItWorksOpen, setIsHowItWorksOpen] = useState(true)
  const [isSelectPlayersOpen, setIsSelectPlayersOpen] = useState(false)
  const [selections, setSelections] = useState<UserPlayerSelection[]>([])
  const [currentSelectionIndex, setCurrentSelectionIndex] = useState(0)
  const [nflDraftSelections, setNflDraftSelections] = useState<NflDraftSelection[]>([])
  const [filteredNflDraftSelections, setFilteredNflDraftSelections] = useState<NflDraftSelection[]>([])
  const [rounds, setRounds] = useState<number[]>(ALL_ROUNDS)
  const [startingRound, setStartingRound] = useState<number>(1)

  useEffect(() => {
    const fetchAllNflDraftSelections = async () => {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/draft-selections/all`, { nflDraftId: 1 })
      const { data } = res
      if (data.success) {
        const _selections = data.nflDraftSelections
        setNflDraftSelections(_selections)
      }
    }

    fetchAllNflDraftSelections()
  }, [])

  useEffect(() => {
    const getUserSelections = async () => {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/selections/current`, {}, { withCredentials: true })
      const { data } = res
      if (data.success) {
        const { userPlayerSelections } = data
        setSelections(userPlayerSelections)
      }
    }

    if (currentNflDraft) {
      if (user) {
        getUserSelections()
      } else {
        for (let i = 0; i < 6; i++) {
          setSelections((selections) => [...selections, { id: 0, index: i, nflDraftSelection: undefined, nflDraftId: currentNflDraft.id }])
        }
      }
    }
  }, [currentNflDraft, user])

  const selectNflDraftSelection = async (index: number, nflDraftSelection: NflDraftSelection) => {
    const _selections = [...selections]
    _selections[index].nflDraftSelection = nflDraftSelection
    console.log(_selections)
    const id = _selections[index].id
    setSelections(_selections)
    setIsSelectPlayersOpen(false)
    if (user && id > 0) {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/selections/update`, { id, nflDraftSelectionId: nflDraftSelection.id }, { withCredentials: true })
      const { data } = res
      console.log(data)
    }
  }

  const filterNflDraftSelectionsByRound = (round: number) => {
    const _filteredNflDraftSelections = nflDraftSelections.filter(selection => selection.round === round)
    setFilteredNflDraftSelections(_filteredNflDraftSelections)
  }

  const handlePlayerCardClick = (index: number) => {
    setCurrentSelectionIndex(index)
    if (index === 0) {
      setRounds(ALL_ROUNDS)
      setStartingRound(1)
      const _filteredNflDraftSelection = nflDraftSelections
      setFilteredNflDraftSelections(_filteredNflDraftSelection)
      filterNflDraftSelectionsByRound(1)
    } else if (index < 3) {
      setRounds(ALL_ROUNDS_AFTER_FIRST)
      setStartingRound(2)
      const _filteredNflDraftSelection = nflDraftSelections.filter(selection => selection.round > 1)
      setFilteredNflDraftSelections(_filteredNflDraftSelection)
      filterNflDraftSelectionsByRound(2)
    } else {
      setRounds(ALL_ROUNDS_AFTER_THIRD)
      setStartingRound(4)
      const _filteredNflDraftSelection = nflDraftSelections.filter(selection => selection.round > 3)
      setFilteredNflDraftSelections(_filteredNflDraftSelection)
      filterNflDraftSelectionsByRound(4)
    }
    setIsSelectPlayersOpen(true)
  }

  return (
    <>
      <Stack w="100%">
        <Text fontSize="3xl" fontWeight="bold">
          Armchair GMs Invitational
        </Text>
        <HStack pt={5} pb={5}>
          <OutlineButton mr={3} onClick={() => setIsHowItWorksOpen(true)}>
            Rules
          </OutlineButton>
          <Spacer />
        </HStack>
        <Text fontSize="md" fontWeight="bold">
          Make your selections:
        </Text>
        <VStack p={10}>
          <SelectPlayerItem
            key={0}
            index={0}
            isLocked={false}
            width="33%"
            selection={selections[0]}
            handlePlayerCardClick={() => handlePlayerCardClick(0)}
          />
          <HStack w="66%" spacing={10}>
            <SelectPlayerItem
              key={1}
              index={1}
              isLocked={false}
              width="100%"
              selection={selections[1]}
              handlePlayerCardClick={() => handlePlayerCardClick(1)}
            />
            <SelectPlayerItem
              key={2}
              index={2}
              isLocked={false}
              width="100%"
              selection={selections[2]}
              handlePlayerCardClick={() => handlePlayerCardClick(2)}
            />
          </HStack>
          <Grid w="100%" templateColumns={`repeat(3, 1fr)`} gap={5}>
            {
              selections.slice(3).map((selection, index) => {
                return (
                  <SelectPlayerItem
                    key={index + 3}
                    index={index + 3}
                    isLocked={false}
                    width="100%"
                    selection={selection}
                    handlePlayerCardClick={() => handlePlayerCardClick(index + 3)}
                  />
                )
              })
            }
          </Grid>
        </VStack>
      </Stack>
      <ArmchairGMsInvitationalModal isOpen={isHowItWorksOpen} onClose={() => setIsHowItWorksOpen(false)} />
      <SelectPlayerModal
        isOpen={isSelectPlayersOpen}
        onClose={() => setIsSelectPlayersOpen(false)}
        rounds={rounds}
        nflDraftSelections={filteredNflDraftSelections}
        startingRound={startingRound}
        filterNflDraftSelectionsByRound={filterNflDraftSelectionsByRound}
        selectPlayer={selectNflDraftSelection}
        selectionIndex={currentSelectionIndex}
      />
    </>
  )
}