import { useNavigate } from "react-router-dom"
import { HStack, Spacer, Stack, Text, useColorModeValue, VStack } from "@chakra-ui/react"
import { Rating, UserPlayerRating } from "../../../types"
import { getRatingBottomText, getRatingTopText } from "../../../utils"

interface Props {
  index: number
  isEnd: boolean
  mb: number
  userPlayerRating: UserPlayerRating
}

export const BigBoardPlayerItem = ({ index, isEnd, mb, userPlayerRating }: Props) => {
  const bg = useColorModeValue("light.100", "dark.100")
  const bg2 = useColorModeValue("light.200", "dark.200")
  const navigate = useNavigate()
  const rating: Rating = {
    round: userPlayerRating.round,
    userRankMin: userPlayerRating.userRankMin,
    userRankMax: userPlayerRating.userRankMax,
    label: userPlayerRating.label,
  }
  
  return (
    <Stack
      borderTopRadius={index === 0 ? 10 : 0}
      borderBottomRadius={isEnd ? 10 : 0}
      cursor="pointer"
      p={5}
      mb={mb}
      bg={ index % 2 === 0 ? bg : bg2}
      onClick={() => navigate(`/player/${userPlayerRating.player.id}`)}
    >
      <HStack pl={2} pr={2}>
        <Stack>
          <Text fontSize="2xl" fontWeight="bold">
            { `${userPlayerRating.player.firstName} ${userPlayerRating.player.lastName}` }
          </Text>
          <HStack>
            <Text
              color="grey.400"
              fontSize="md"
              fontWeight="bold"
            >
              { `${userPlayerRating.player.school.alias ? userPlayerRating.player.school.alias : userPlayerRating.player.school.name } - ` }
            </Text>
            <Text fontWeight="bold" color="brand.500" fontSize="md">
              { userPlayerRating.player.position.name }
            </Text>
          </HStack>
        </Stack>
        <Spacer />
        <VStack spacing={0} borderRadius={10}>
          <Text fontSize="xs" fontWeight="bold" textAlign="center">
            { getRatingTopText(rating) }
          </Text>
          <Text fontSize="2xl" fontWeight="bold" textAlign="center">
            { `${getRatingBottomText(rating)}` }
          </Text>
          {
            (rating.round === 1 || rating.userRankMin === 33) && (
              <Text fontSize="2xs" fontWeight="bold">
                { `Round ${rating.round}` }
              </Text>
            )
          
          }
        </VStack>
      </HStack>
    </Stack>
  )
}