import { HStack, Spacer, Stack, Text } from "@chakra-ui/react"
import { NflDraftSelection, Player, Team } from "../../../types"
import { Section } from "../../ui"

interface Props {
  index: number
  nflDraftSelection: NflDraftSelection
  player: Player
  team: Team
  pickNumber: number
  selectPlayer: (index: number, nflDraftSelection: NflDraftSelection) => void
}

export const PlayerListItem = ({ index, nflDraftSelection, pickNumber, player, team, selectPlayer }: Props) => {
  return (
    <Section w="100%" cursor="pointer" p={5} mb={5} onClick={() => selectPlayer(index, nflDraftSelection)}>
      <HStack pl={2} pr={2}>
        <Text
          fontSize="lg"
          fontWeight="bold"
          pr={5}
        >
          #{ pickNumber }
        </Text>
        <Stack>
          <Text
            fontSize="lg"
            fontWeight="bold"
          >
            { `${player.firstName} ${player.lastName}` }
          </Text>
          <HStack>
            <Text
                color="#8b8b9b"
                fontSize="md"
                fontWeight="bold"
            >
              { `${player.school.name} - ` }
            </Text>
            <Text color="brand.500" fontWeight="bold" fontSize="md">
              { player.position.name }
            </Text>
          </HStack>
        </Stack>
        <Spacer />
        <Text fontWeight="bold" fontSize="2xl">
          { team.abbreviation }
        </Text>
      </HStack>
    </Section>
  )
}