import { Select } from "@chakra-ui/react"

interface Props {
  year: number | null
  years: number[]
  handleYearChange: (value: string) => void
}

export const YearSelect = ({ year, years, handleYearChange }: Props) => {
  return (
    <>
      {
        years.length > 0 && (
          <Select
            variant="filled"
            w="30%"
            bg="white"
            color="brand.50"
            fontWeight="bold"
            value={year ? year : years[0]}
            onChange={(event) => handleYearChange(event.target.value)}
          >
            {
              years.map(_year => {
                return (
                  <option key={_year} value={_year}>{ _year }</option>            
                )
              })
            }
          </Select>
        )
      }
    </>
  )
}