import { HStack, Link, Stack, Text } from "@chakra-ui/react"
import { useDeviceContext, useUserContext } from "../../../hooks"

export const FavoriteTeamSection = () => {
  const { isMobile } = useDeviceContext()
  const { user } = useUserContext()
  
  return (
    <>
      {
        isMobile ? (
          <Stack p={5}>
            <Text fontWeight="bold" pr={5}>
              Favorite Team
            </Text>
            <Text as={Link} textDecoration="underline">
              { user?.team?.name }
            </Text>
          </Stack>
        ) : (
          <HStack p={5}>
            <Text fontWeight="bold" pr={5}>
              Favorite Team
            </Text>
            <Text as={Link} textDecoration="underline">
              { user?.team?.name }
            </Text>
          </HStack>
        )
      }
    </>
  )
}