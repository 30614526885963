import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Link,
  Flex,
} from "@chakra-ui/react"

interface Props {
  isOpen: boolean
  onClose: () => void
  btnRef: any
}
 
export const DrawerComponent = ({ isOpen, onClose, btnRef }: Props) => {
  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      finalFocusRef={btnRef}
    >
      <DrawerOverlay />
 
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Chakra</DrawerHeader>
 
        <DrawerBody>
          <Flex flexDirection="column">
            <Link mb="5">About</Link>
            <Link>More Apps</Link>
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}