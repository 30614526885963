import { useState } from "react"
import { Stack, Text } from "@chakra-ui/react"
import { Helmet } from "react-helmet"
import { BigBoardStatsComponent } from "./ui"
import { MenuItemsSection } from "../ui"
import { MenuItems, MENU_ITEM_BIG_BOARD, MENU_ITEM_BOOM_BUST, MENU_ITEM_MOCK_DRAFTS } from "../../constants"

export const StatsComponent = () => {
  const [currentMenuItem, setCurrentMenuItem] = useState<string>(MENU_ITEM_BIG_BOARD)

  return (
    <>
      <Helmet>
        <title>Your NFL Prospect Stats - Armchair GMs</title>
        <meta name="description" content="Your NFL prospect stats based on how accurate you are at predicting how well the draft prospects perform. View a side by side comparison between you and NFL GMs to see how well your scouting skills compare to theirs. See where you rank among the other Armchair GMs. Check back when next season starts to get your stats." />
      </Helmet>
      <Stack h="100%" w="100%" pb={10} spacing={10}>
        <Text fontSize="2xl" fontWeight="bold">
          Your Stats
        </Text>
        <MenuItemsSection currentMenuItem={currentMenuItem} menuItems={MenuItems} setCurrentMenuItem={setCurrentMenuItem} />
        <BigBoardStatsComponent />
      </Stack>
    </>
  )
}