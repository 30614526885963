import axios from "axios"
import { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { useParams } from "react-router-dom"
import {
  AspectRatio,
  HStack,
  Stack,
  Text,
} from "@chakra-ui/react"
import { SchoolsSelect } from "./ui"
import { PlayerItem, PositionsSelect } from "../ui"
import { useDeviceContext, useUserContext } from "../../hooks"
import { Film, Player, Position, School } from "../../types"

export const FilmComponent = () => {
  const { isMobile } = useDeviceContext()
  const { user } = useUserContext()
  const params = useParams()
  const [positions, setPositions] = useState<Position[]>([])
  const [film, setFilm] = useState<Film | null>(null)
  const [players, setPlayers] = useState<Player[]>([])
  const [filteredPlayers, setFilteredPlayers] = useState<Player[]>([])
  const [schools, setSchools] = useState<School[]>([])
  const [currentPositionStr, setCurrentPositionStr] = useState("All")
  const [currentSchoolStr, setCurrentSchoolStr] = useState("All")
  
  useEffect(() => {
    const fetchPositions = async () => {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/positions/all`)
      const { data } = res
      if (data.success) {
        const { positions } = data
        const _positions = [{ id: 999, name: "All" }, ...positions]
        setPositions(_positions)
      }
    }

    fetchPositions()
  }, [])

  useEffect(() => {
    const fetchFilm = async (id: number) => {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/films/search/${id}`)
      const { data } = res
      if (data.success) {
        const { film, players, schools } = data
        setFilm(film)
        setPlayers(players)
        setFilteredPlayers(players)
        setSchools(schools)
      }
    }

    const { id } = params
    if (id) {
      fetchFilm(parseInt(id))
    }
  }, [params])

  const filterByPosition = (position: string) => {
    setCurrentPositionStr(position)
    if (position === "All") {
      handleSchoolChange(currentSchoolStr)
    } else {
      const _filteredPlayers = players.filter((player) => {
        return player.position.name === position
      })
      if (currentSchoolStr === "All") {
        setFilteredPlayers(_filteredPlayers)
      } else {
        const __filteredPlayers = _filteredPlayers.filter((player) => {
          return player.school.name === currentSchoolStr
        })
        setFilteredPlayers(__filteredPlayers)
      }
    }
  }

  const filterBySchool = (school: string) => {
    setCurrentSchoolStr(school)
    if (school === "All") {
      handlePositionChange(currentPositionStr)
    } else {
      const _filteredPlayers = players.filter((player) => {
        return player.school.name === school
      })
      if (currentPositionStr === "All") {
        setFilteredPlayers(_filteredPlayers)
      } else {
        const __filteredPlayers = _filteredPlayers.filter((player) => {
          return player.position.name === currentPositionStr
        })
        setFilteredPlayers(__filteredPlayers)
      }
    }
  }

  const handlePositionChange = (position: string) => {
    if (position === "All") {
      setFilteredPlayers(players)
    } else {
      const _filteredPlayers = players.filter((player) => {
        return player.position.name === position
      })
      setFilteredPlayers(_filteredPlayers)
    }
  }

  const handleSchoolChange = (school: string) => {
    if (school === "All") {
      setFilteredPlayers(players)
    } else {
      const _filteredPlayers = players.filter((player) => {
        return player.school.name === school
      })
      setFilteredPlayers(_filteredPlayers)
    }
  }

  return (
    <>
      {
        film && schools.length > 1 && (
          <Helmet>
            <title>{ `${film.title} - Armchair GMs` } </title>
            <meta name="description" content={`Watch NFL prospect film from ${schools[0].alias ? schools[0].alias : schools[0].name} and ${schools[1].alias ? schools[1].alias : schools[1].name}.`} />
          </Helmet>
        )
      }
      <Stack h="100%" w="100%" pb={10} pt={isMobile ? 65 : 0} pl={ isMobile ? 5 : 0 } pr={ isMobile ? 5 : 0 }>
        {
          film && (
            <Stack w="100%">
              <Text fontSize="xl" fontWeight="bold">{film.title}</Text>
              <AspectRatio ratio={16 / 9} borderRadius={10}>
                <iframe title={film.title} src={film.url.replace("watch?v=", "embed/")} width="100%" height="100%" allow="autoplay"/>
                {/* <ReactPlayer width="100%" height="100%" url={"https://drive.google.com/file/d/1u0Kug1oDv-6brABtqHpazAMWgvcV1URX/preview"} controls={true} /> */}
              </AspectRatio>
            </Stack>
          )
        }
        <Stack pb={10} pl={5} pr={5} pt={10}>
          <HStack w="100%" spacing={10} pt={5}>
            <SchoolsSelect schools={schools} handleSchoolChange={filterBySchool} />
            <PositionsSelect positions={positions} handlePositionChange={filterByPosition} />
          </HStack>
          <Stack pt={10} spacing={0}>
            {
              filteredPlayers.map((player, index) => {
                return (
                  <PlayerItem key={index} player={player} hideFilmsBox={true} isChecked={user?.playerRatings?.some(rating => rating.playerId === player.id)} />
                )
              })
            }
          </Stack>
        </Stack>
      </Stack>
    </>
  )
}