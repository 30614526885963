import {
  Modal,
  ModalCloseButton,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Stack,
  Text,
} from "@chakra-ui/react"
import { UserStats } from "../../../types"
import { getLabelFromMinRank } from "../../../utils"

interface Props {
  isOpen: boolean
  stats: UserStats
  onClose: () => void
}

const RatingItem = ({ label, rank }: { label: string, rank: number }) => {
  return (
    <Stack>
      <Text fontSize="sm" fontWeight="bold" pt={5}>
        { label }
      </Text>
      <Text fontSize="2xl" fontWeight="bold">
        { getLabelFromMinRank(rank) }
      </Text>
    </Stack>
  )
}

export const DetailedStatsModal = ({ isOpen, stats, onClose }: Props) => {
  return (
    <Modal size="4xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton color="grey" />
        <ModalBody h="100%">
          <Stack p={5}>
            <Text fontSize="xl" fontWeight="bold" pt={5}>
              { `${stats.player.firstName} ${stats.player.lastName} Performance:` }
            </Text>
            <Text fontSize="3xl" fontWeight="bold" pb={10}>
              { stats.performanceRank }
            </Text>
            <RatingItem label="Your rating:" rank={stats.rating.userRankMin} />
            <RatingItem label="NFL GMs rating:" rank={stats.pickNumber} />
            <RatingItem label="Other Armchair GMs rating:" rank={stats.averagePlayerMinRank} />
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}