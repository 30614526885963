import { useState } from "react"
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai"
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  HStack,
  IconButton,
  Spacer,
  useDisclosure
} from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"
import { Navigation, SignUpModal } from "./"
import { useUserContext } from "../../../hooks"

export const NavBar = () => {
  const navigate = useNavigate()
  const { isOpen, onToggle, onClose } = useDisclosure()
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false)
  const { user } = useUserContext()

  return (
      <>
        <Box w="100%" h="50px" position="fixed" bg="brand.100" zIndex={100}>
          <HStack>
            <IconButton
              h={50}
              w={50}
              onClick={onToggle}
              icon={
                isOpen ? <AiOutlineClose width={50} height="100%" color="white" /> : <AiOutlineMenu width={50} height="100%" color="white" />
              }
              variant="ghost"
              aria-label={"Toggle Navigation"}
            />
            <Spacer />
            {
              user ? (
                <Button color="white" bg="transparent" onClick={() => navigate(`/profile/${user.username}`)}>
                  { user.username }
                </Button>
              ) : (
                <Button color="white" bg="transparent" onClick={() => setIsSignUpModalOpen(true)}>
                  Sign Up
                </Button>
              )
            }
          </HStack>
          <Drawer
            isOpen={isOpen}
            placement="left"
            onClose={onClose}
          >
            <DrawerOverlay />
            <DrawerContent bg="#1A1A1E">
              <DrawerCloseButton color="white" />
              <DrawerBody>
                <Navigation />
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </Box>
        <SignUpModal isOpen={isSignUpModalOpen} onClose={() => setIsSignUpModalOpen(false)} handleSuccessfulSignIn={() => setIsSignUpModalOpen(false)} />
      </>
    )
}