import { useEffect } from "react"
import { Stack, Text } from "@chakra-ui/react"

export const TermsPage = () => {
  useEffect(() => {
    document.title = "Terms - Armchair GMs"
  }, [])

  return (
    <Stack spacing={5} p={10} bg="white">
      <Text color="black">
          Last Revised: October 25, 2022
      </Text>
      <Text color="black">
          The following are terms of a legal agreement between Armchair GMs and you. By accessing, reading and using this website you acknowledge that you have read, understood and agree to be bound by the terms which follow and to comply with all applicable laws and regulations. If you do not agree to these terms, do not use this website. It may contain other proprietary notices and copyright information, the terms of which must be observed and followed. Information on this website may be technically inaccurate or have errors. Be aware that Armchair GMs may, in its sole discretion and without notice, revise these terms at any time.
      </Text>
      <Text fontWeight="bold" color="black">
          COPYRIGHTS AND USE OF WEBSITE CONTENT
      </Text>
      <Text color="black">
          The copyright in all materials provided on this website is held by Armchair GMs or by the original creator of the material. All rights not expressedly granted are reserved. Permission is granted to download one copy of the materials on this website on a single computer for your personal use provided that you retain all copyright and other proprietary notices contained in the materials. You may not “mirror” any material contained on this website without the express written permission of Armchair GMs. All content and functionality on this website, including text, graphics, logos, icons, and images and the selection and arrangement thereof, are the exclusive property of Armchair GMs and are protected by U.S. and international copyright laws.
      </Text>
      <Text fontWeight="bold" color="black">
          HYPERLINKING AND THIRD PARTY WEBSITES
      </Text>
      <Text color="black">
          Armchair GMs makes no representations whatsoever about any other website which may be accessed through this one. When you access a non-Armchair GMs website, please understand that it is independent from Armchair GMs, and that Armchair GMs has no control over the content on that website, even if it provides information or services to the owner of that website. In addition, a link or referral to a web site does not mean that Armchair GMs endorses or accepts any responsibility for the content or the use of such web site. In fact, Armchair GMs disclaims any and all liability and responsibility for such content. It is up to you to take precautions to ensure that whatever you select for your use is free of such items as viruses, worms, trojan horses and other items of a destructive nature.
      </Text>
      <Text fontWeight="bold" color="black">
          TRADEMARKS
      </Text>
      <Text color="black">
          The trademarks, service marks, designs and logos (collectively, the “Trademarks”) used and displayed on this website are registered and unregistered Trademarks of Armchair GMs. Other trademarks, service marks and trade names may be owned by others. Nothing on this website should be construed as granting, by implication, estoppel or otherwise, any license or right to use any Trademark or any other Armchair GMs intellectual property including the name Pro Football Focus, without prior written permission.
      </Text>
      <Text fontWeight="bold" color="black">
          USER POSTINGS
      </Text>
      <Text color="black">
          You acknowledge and agree that Armchair GMs shall own and have the unrestricted right to use, publish, in electronic form and otherwise, distribute and exploit any and all information that you post or otherwise publish on this website (“submissions”). You hereby waive any and all claims against Armchair GMs for any alleged or actual infringements of any rights of privacy or publicity, moral rights, rights of attribution or any other intellectual property rights in connection with the use and publication of such submissions by Armchair GMs. Armchair GMs does not represent or endorse the accuracy or reliability of any submissions displayed, uploaded, posted on any message board, or otherwise distributed through this website by any user of this website, information provider or any other third party. Armchair GMs expressly disclaims any and all liability related to submissions, and you acknowledge that any reliance upon such submissions shall be at your sole option, liability and risk. You covenant that you shall not post or otherwise publish on the website any materials that: (i) are threatening, libelous, defamatory, or obscene; (ii) would constitute, or that encourage conduct that would constitute a criminal offense, give rise to civil liability, or otherwise violate law; (iii) infringe the intellectual property, privacy, or other rights of any third parties; (iv) contain a computer virus or other destructive element; (v) contain advertising; or (vi) constitute or contain false or misleading statements. Armchair GMs in its sole discretion reserves the right to refuse to post and the right to remove any information or submission from this website, in whole or in part, for any reason.
      </Text>
      <Text fontWeight="bold" color="black">
          NO SERVICES, ENDORSEMENT OR PROFESSIONAL CONSULTATION
      </Text>
      <Text color="black">
          There may be delays, omissions or inaccuracies in information obtained through your use of this website. The information on the website is provided to you with the understanding that provision of this information to you does not constitute the rendering of investment, consulting, legal, accounting, tax, career or other advice or services. Information on this website should not be relied upon for making business, investment or other decisions or used as a substitute for consultation with professional advisors. Moreover, Armchair GMs does not represent or endorse the accuracy or reliability of any advice, opinion, statement, or other information displayed, uploaded, downloaded or distributed through this website by Armchair GMs, any user, information provider or any other person or entity. You acknowledge that any reliance upon such opinion, advice, statement, memorandum, or information shall be at your sole option, liability and risk. Moreover, Armchair GMs does not grant any license or other authorization to you to use this website in any manner if such use in whole or in part suggests that Armchair GMs promotes or endorses a third party's causes, ideas, political campaigns, political views, web sites, products or services.
      </Text>
      <Text fontWeight="bold" color="black">
          ACCESS TO THIS WEBSITE
      </Text>
      <Text color="black">
          Armchair GMs may alter, suspend or discontinue this website and your access to use this website at any time for any reason without notice or liability to you or any third party. This website may become unavailable due to maintenance or malfunction of computer equipment or for other reasons and may result in damages to the user's systems or operations. The user shall be solely responsible for ensuring that any information or content obtained from this Site does not contain any virus or other computer software code or subroutine designed to disable, erase, impair or otherwise damage the user's systems, software or data.
      </Text>
      <Text fontWeight="bold" color="black">
          DISCLAIMER OF WARRANTIES
      </Text>
      <Text color="black">
          THE WEBSITE AND ALL MATERIALS THEREON ARE DISTRIBUTED ON AN “AS IS” BASIS WITHOUT WARRANTIES OF ANY KIND. TO THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW, Armchair GMs DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT. SPECIFICALLY, BUT WITHOUT LIMITATION, Armchair GMs DOES NOT WARRANT THAT: (1) THE INFORMATION ON THIS WEBSITE IS CORRECT, ACCURATE OR RELIABLE; (2) THE FUNCTIONS CONTAINED ON THIS WEBSITE WILL BE UNINTERRUPTED OR ERROR-FREE; OR (3) DEFECTS WILL BE CORRECTED, OR THAT THIS WEBSITE OR THE SERVER THAT MAKES IT AVAILABLE IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. YOU HEREBY ACKNOWLEDGE THAT USE OF THE WEBSITE IS AT YOUR SOLE RISK. IF YOU ARE A CALIFORNIA RESIDENT, YOU HEREBY WAIVE CALIFORNIA CIVIL CODE SECTION 1542 WHICH PROVIDES: “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM MUST HAVE MATERIALLY AFFECTED HIS SETTLEMENT WITH THE DEBTOR.”
      </Text>
      <Text fontWeight="bold" color="black">
          LIMITATION OF LIABILITY
      </Text>
      <Text color="black">
          UNDER NO CIRCUMSTANCES SHALL Armchair GMs OR ANY OF ITS PREDECESSORS, SUCCESSORS, PARENTS, SUBSIDIARIES, AFFILIATES, COACHES, OFFICERS, DIRECTORS, SHAREHOLDERS, INVESTORS, EMPLOYEES, AGENTS, REPRESENTATIVES, ATTORNEYS AND THEIR RESPECTIVE HEIRS, SUCCESSORS AND ASSIGNS BE LIABLE FOR ANY DAMAGES, INCLUDING DIRECT, INCIDENTAL, PUNITIVE, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES THAT DIRECTLY OR INDIRECTLY RESULT FROM THE USE OF, OR THE INABILITY TO USE, THIS WEBSITE OR THE INFORMATION CONTAINED ON THIS WEBSITE OR OBTAINED FROM YOUR USE OF THIS WEBSITE, INCLUDING FOR VIRUSES ALLEGED TO HAVE BEEN OBTAINED FROM THE WEBSITE, EVEN IF Armchair GMs HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT SHALL IT OR ANY OF ITS PREDECESSORS, SUCCESSORS, PARENTS, SUBSIDIARIES, AFFILIATES, COACHES,OFFICERS, DIRECTORS, SHAREHOLDERS, INVESTORS', EMPLOYEES', AGENTS', REPRESENTATIVES' AND ATTORNEYS' AND THEIR RESPECTIVE HEIRS', SUCCESSORS' AND ASSIGNS' SHARE IN ANY LIABILITY. TOTAL LIABILITY DEVOLVES TO YOU FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION WHETHER IN CONTRACT, TORT (INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE) OR OTHERWISE EXCEEDING $1. SOME JURISDICTIONS MAY NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES IN WHICH CASE SOME OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO ALL USERS.
      </Text>
      <Text fontWeight="bold" color="black">
          INDEMNIFICATION
      </Text>
      <Text color="black">
          You hereby indemnify, defend, and hold harmless Armchair GMs and all of its predecessors, successors, parents, subsidiaries, affiliates, coaches, officers, directors, shareholders, investors, employees, agents, representatives and attorneys and their respective heirs, successors and assigns (collectively, the “Indemnified Parties”) from and against any and all liability and costs, including, without limitation, reasonable attorneys' fees, incurred by the Indemnified Parties in connection with any claim arising out of or relating to any breach by you of this agreement or the representations, warranties, and covenants you have made by agreeing to the terms of this agreement. You shall cooperate as fully as reasonably required in the defense of any such claim. Bridgers reserves the right, at its own expense, to assume the exclusive defense and control of any matter subject to indemnification by you.
      </Text>
      <Text fontWeight="bold" color="black">
          ENFORCEMENT OF TERMS AND CONDITIONS
      </Text>
      <Text color="black">
          This agreement is governed and interpreted pursuant to the laws of the State of Virginia, United States of America, notwithstanding any principles of conflicts of law. You expressly agree that exclusive jurisdiction resides in the courts of the State of Virginia. You further agree and expressly consent to the exercise of personal jurisdiction in the State of Virginia in connection with any dispute or claim involving Armchair GMs. If any part of these terms is unlawful, void, or unenforceable, that part will be deemed severable and will not affect the validity and enforceability of the remaining provisions.
      </Text>
      <Text fontWeight="bold" color="black">
          INFRINGEMENT NOTICES AND TAKEDOWN
      </Text>
      <Text color="black">
          Armchair GMs prohibits the posting of any information that infringes or violates the copyright rights and/or other intellectual property rights (including rights of privacy and publicity) of any person or entity. If you believe that any material contained on this website infringes your copyright or other intellectual property rights, you should notify Armchair GMs of your copyright infringement claim in accordance with the following procedure. Armchair GMs will process notices of alleged infringement which it receives and will take appropriate action as required by the Digital Millennium Copyright Act (“DMCA”). The DMCA requires that notifications of claimed copyright infringement should be sent to this website's Designated Agent:
      </Text>
      <Text fontWeight="bold" color="black">
          ENTIRE AGREEMENT
      </Text>
      <Text color="black">
          This agreement constitutes the entire agreement between Armchair GMs and you with respect to the subject matter of this agreement and supersedes and replaces all prior or contemporaneous understandings or agreements, written or oral, regarding that subject matter. Any waiver of any provision of this agreement will be effective only if in writing and signed by Armchair GMs.
      </Text>
    </Stack>
  )
}