import { HStack, Input, Stack, Text } from "@chakra-ui/react"
import { useDeviceContext, useUserContext } from "../../../hooks"

export const UsernameSection = () => {
  const { isMobile } = useDeviceContext()
  const { user } = useUserContext()

  return (
    <>
      {
        isMobile ? (
          <Stack p={5}>
            <Text fontWeight="bold" pr={5}>
              Username
            </Text>
            <Input
              variant="filled"
              fontWeight="bold"
              focusBorderColor="brand.50"
              placeholder={user?.username}
              disabled
            />
          </Stack>
        ) : (
          <HStack p={5}>
            <Text fontWeight="bold" pr={5}>
              Username
            </Text>
            <Input
              variant="filled"
              fontWeight="bold"
              focusBorderColor="brand.50"
              placeholder={user?.username}
              disabled
            />
          </HStack>
        )
      }
    </>
  )
}