import axios from "axios"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Box, HStack, Stack, Text, VStack } from "@chakra-ui/react"
import { BigBoardPlayerItem } from "./BigBoardPlayerItem"
import { GradientButton, NflDraftYearSelect, PositionsSelect, SearchBar } from "../../ui"
import { NflDraft, Position, UserPlayerRating } from "../../../types"
import { getRatingBottomText, getRatingTopText } from "../../../utils"

interface Props {
  currentNflDraft: NflDraft | null
  isUserProfile: boolean
  nflDrafts: NflDraft[]
  userPlayerRatings: UserPlayerRating[]
  handleNflDraftChange: (value: string) => void
}

export const BigBoardSection = ({
  currentNflDraft,
  isUserProfile,
  nflDrafts,
  userPlayerRatings,
  handleNflDraftChange,
}: Props) => {
  const navigate = useNavigate()
  const [searchPlayerText, setSearchPlayerText] = useState<string>("")
  const [positions, setPositions] = useState<Position[]>([])
  const [filteredUserPlayerRatings, setFilteredUserPlayerRatings] = useState<UserPlayerRating[]>([])
  const [currentPosition, setCurrentPosition] = useState("All Positions")

  useEffect(() => {
    const fetchPositions = async () => {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/positions/all`)
      const { data } = res
      if (data.success) {
        const { positions } = data
        const _positions = [{ id: 999, name: "All Positions" }, ...positions]
        setPositions(_positions)
      }
    }

    fetchPositions()
  }, [])

  useEffect(() => {
    setFilteredUserPlayerRatings(userPlayerRatings)
  }, [userPlayerRatings])

  const handlePositionChange = (position: string) => {
    setCurrentPosition(position)
    if (position === "All Positions") {
      setFilteredUserPlayerRatings(userPlayerRatings)
    } else {
      const _filteredUserPlayerRatings = userPlayerRatings.filter((userPlayerRating) => {
        return userPlayerRating.player.position.name === position
      })
      setFilteredUserPlayerRatings(_filteredUserPlayerRatings)
    } 
  }

  const searchPlayers = (name: string) => {
    setSearchPlayerText(name)
    if (name.length === 0) {
      handlePositionChange(currentPosition)
    } else if (name.length > 0) {
      const searchedPlayers = userPlayerRatings.filter(userPlayerRating => { return `${userPlayerRating.player.firstName} ${userPlayerRating.player.lastName}`.toLowerCase().includes(name.toLowerCase()) })
      if (currentPosition !== "All Positions") {
        const _filteredPlayers = searchedPlayers.filter((p) => {
          return p.player.position.name === currentPosition
        })
        setFilteredUserPlayerRatings(_filteredPlayers)
      } else {
        setFilteredUserPlayerRatings(searchedPlayers)
      }
    }
  }

  return (
    <Stack p={5}>
      <Text fontSize="4xl" fontWeight="bold" pt={5} pb={5}>Big Board</Text>
      <Stack pb={10}>
        <Text fontWeight="bold">NFL Draft Year</Text>
        <NflDraftYearSelect
          nflDraft={currentNflDraft ? currentNflDraft.year.toString() : undefined}
          nflDrafts={nflDrafts}
          handleNflDraftChange={handleNflDraftChange}
        />
        <HStack w="100%" spacing={5} pt={5}>
          <SearchBar placeHolder="Search Players" text={searchPlayerText} handleTextChange={searchPlayers} />
          <PositionsSelect positions={positions} handlePositionChange={handlePositionChange} hideHeader/>
        </HStack>
      </Stack>
      {
         filteredUserPlayerRatings.length === 0 && (
          <VStack>
            <Text fontSize="2xl" pt={10} pb={5} fontWeight="bold" textAlign="center">
              Nothing here yet
            </Text>
            {
              isUserProfile && (
                <GradientButton onClick={() => navigate(`/players`)}>
                  Click here to get started
                </GradientButton>
              )
            }
          </VStack>
        )
      }
      <Stack spacing={0} borderRadius={10}>
        {
          filteredUserPlayerRatings.map((userPlayerRating, index) => {
            return (
              <Box key={index}>
                { (index === 0 || userPlayerRating.userRankMin !== filteredUserPlayerRatings[index - 1].userRankMin) && (
                  <Text fontSize="sm" fontWeight="bold" pt={3} pb={2}>
                    { `${getRatingTopText(userPlayerRating)} ${getRatingBottomText(userPlayerRating)}` }
                  </Text>
                ) }
                <BigBoardPlayerItem
                  index={index}
                  isEnd={index === filteredUserPlayerRatings.length - 1}
                  mb={index !== filteredUserPlayerRatings.length - 1 && userPlayerRating.userRankMin !== filteredUserPlayerRatings[index + 1].userRankMin ? 3 : 0}
                  userPlayerRating={userPlayerRating}
                />
              </Box>
            )
          })
        }
      </Stack>
    </Stack>
  )
}