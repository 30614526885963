import { AxiosInstance } from "axios"
import { useContext, useEffect } from "react"
import styled from "styled-components"
import { Box, Stack, useColorModeValue } from "@chakra-ui/react"
import { NavBar, Navigation, UserPanel } from "./ui"
import { CompetitionComponent } from "../Competition"
import { HomeComponent } from "../Home"
import { PlayerComponent, PlayersComponent } from "../Players"
import { SettingsComponent } from "../Settings"
import { NoProfileComponent, UserProfileComponent } from "../User"
import { NavOption } from "../../types"
import { UserContext, getUser, removeSessionCookie, setSessionCookie, useDeviceContext } from "../../hooks"
import { LeaderboardComponent } from "../Leaderboard"
import { FilmComponent } from "../Film"
import { StatsComponent } from "../Stats"

const MainContainer = styled(Box)``
MainContainer.defaultProps = {
  width: "65%",
  height: "100%",
  position: "fixed",
  left: "10%",
  top: 0,
  overflowY: "scroll",
  overflowX: "hidden",
}

const LeftPanelContainer = styled(Box)``
LeftPanelContainer.defaultProps = {
  width: "10%",
  height: "calc(100%)",
  position: "fixed",
  left: 0,
  top: 0,
  background: "brand.100",
  overflowX: "hidden",
  overflowY: "scroll",
}

const RightPanelContainer = styled(Stack)``
RightPanelContainer.defaultProps = {
  width: "25%",
  height: "100%",
  position: "fixed",
  right: 0,
  top: 0,
  overflowX: "hidden",
  overflowY: "scroll",
  background: "transparent",
}

interface Props {
  currentNavOption: string
  axios: AxiosInstance
}

export const DashboardPage = ({ currentNavOption, axios }: Props) => {
  const bg = useColorModeValue("light.background", "dark.background")
  const { isMobile } = useDeviceContext()
  
  const { user, setUser } = useContext(UserContext)

  useEffect(() => {
    const checkSessionStatus = async () => {
      try {
        const _user = await getUser()
        if (_user) {
          const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/me`, {}, { withCredentials: true })
          if (res.status !== 200) {
            removeSessionCookie()
          } else {
            const { data } = res
            if (data.success) {
              setUser(data.user)
              setSessionCookie(data.user)
            }
          }
        } else {
          return
        }
      } catch(e) {
        console.log("removing session cookie")
        removeSessionCookie()
      }
    }

    if (user) {
      checkSessionStatus()
    }
  }, [])

  const getComponent = () => {
    switch(currentNavOption) {
      case NavOption.HOME:
        return (
          <HomeComponent />
        )
      case NavOption.INVITATIONAL:
        return (
          <CompetitionComponent />
        )
      case NavOption.PLAYERS:
        return (
          <PlayersComponent />
        )
      case NavOption.PROFILE:
        return (
          <UserProfileComponent />
        )
      case NavOption.SETTINGS:
        return (
          <SettingsComponent />
        )
      case NavOption.PLAYER:
        return (
          <PlayerComponent />
        )
      case NavOption.LEADERBOARD:
        return (
          <LeaderboardComponent />
        )
      case NavOption.FILM:
        return (
          <FilmComponent />
        )
      case NavOption.STATS:
        return (
          <StatsComponent />
        )
      case NavOption.DEMO:
        return (
          <NoProfileComponent />
        )
      default:
        return (
          <HomeComponent />
        )
    }
  }

  return (
    <>
      {
        isMobile ? (
          <Box h="100%" bg={bg}>
            <NavBar />
            { getComponent() }
          </Box>
        ) : (
          <>
            <LeftPanelContainer>
              <Navigation />
            </LeftPanelContainer>
            <Stack h="100vh" bg={bg}>
              <MainContainer p={ currentNavOption === NavOption.PLAYER || currentNavOption === NavOption.HOME ? 0 : 10 }>
                { getComponent() }
              </MainContainer>
            </Stack>
            <RightPanelContainer>
              <UserPanel />
            </RightPanelContainer>
          </>
        ) 
      }
    </>
  )
}