import { useNavigate } from "react-router-dom"
import { Flex, Text, Link, HStack, Spacer } from "@chakra-ui/react"
 
export const Footer = () => {
  const navigate = useNavigate()
  return (
    <HStack w="50vw" minH="20vh" spacing={5}>
      <Text fontSize="xs">
        © 2024 Armchair GMs, LLC
      </Text>
      <Spacer />
      <Text
        as={Link}
        cursor="pointer"
        onClick={() => navigate(`/privacy`)}
      >
        Privacy Policy
      </Text>
      <Text
        as={Link}
        cursor="pointer"
        onClick={() => navigate(`/terms`)}
      >
        Terms of Service
      </Text>
      <Text
        as={Link}
        cursor="pointer"
        onClick={() => navigate(`/contact-us`)}
      >
        Contact Us
      </Text>
    </HStack>
  )
}