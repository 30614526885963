import { Select, Stack, Text } from "@chakra-ui/react"
import { Position } from "../../types"

interface Props {
  positions: Position[]
  handlePositionChange: (value: string) => void
  hideHeader?: boolean
}

export const PositionsSelect = ({ positions, handlePositionChange, hideHeader }: Props) => {
  return (
    <Stack w="100%">
      {
        !hideHeader &&
        <Text fontWeight="bold">Positions</Text>
      }
      <Select
        variant="filled"
        bg="white"
        color="brand.50"
        fontWeight="bold"
        onChange={(event) => handlePositionChange(event.target.value)}
      >
        {
          positions.map(position => {
            return (
              <option key={position.name} value={position.name}>{ position.name }</option>            
            )
          })
        }
      </Select>
    </Stack>
  )
}