import { useContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import {
  Box,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  VStack,
  HStack,
  Button,
  Spacer,
} from "@chakra-ui/react"
import { SignUpModal } from "../../Main/ui"
import { UserContext } from "../../../hooks"

interface Props {
  isOpen: boolean
  onClose: () => void
}

const InstructionBox = ({ title, text }: { title: string, text: string }) => {
  return (
    <Box
      w={400}
      minH={300}
      bg="rgba(0,0,0,0.7)"
      border="1px solid #C19E72"
      p={5}
    >
      <Text color="#C19E72" fontSize="2xl" fontWeight="bold" textAlign="center" pb={5}>
        { title }
      </Text>
      <Text color="white" fontSize="md">
        { text }
      </Text>
    </Box>
  )
}


export const ArmchairGMsInvitationalModal = ({ isOpen, onClose }: Props) => {
  const { user } = useContext(UserContext)
  const navigate = useNavigate()
  const [isSignUpOpen, setIsSignUpOpen] = useState<boolean>(false)

  const handleEnterClick = () => {
    if (user) {
      onClose()
    } else {
      setIsSignUpOpen(true)
    }
  }

  const handleSuccessfulSignIn = () => {
    setIsSignUpOpen(false)
    onClose()
  }

  return (
    <Modal isOpen={isOpen} size="full" onClose={onClose} closeOnEsc={false}>
      <ModalOverlay/>
      <ModalContent
        bgImage={`url("https://freight.cargo.site/t/original/i/287fee0024d8eb8880dd56089b3e271f37311e46ff70d59dded4e0eb4e513fc4/VALORANT_2023_Premier_StyleFrame_ShieldDark-1-2048x1152.jpeg")`}
        backgroundSize="cover"
        backgroundPosition="center"
      >
        <ModalBody h="100vh">
          <HStack>
            <Link
              href="/"
              color="white"
              fontSize="md"
              fontWeight="bold"
              onClick={() => navigate("/")}
            >
              Go Back
            </Link>
          </HStack>
          <VStack w="100%" h="90vh">
            <Text pt={20} pb={5} fontSize="7xl" textAlign="center" fontWeight="extrabold" color="#C19E72">
              Armchair GMs Invitational
            </Text>
            <Spacer />
            <HStack pb={40} spacing={10}>
              <InstructionBox title="Evaluate" text="Evaluate prospects for the upcoming NFL Draft to determine which prospects catch your eye." />
              <InstructionBox title="Select" text="Select prospects that you think are undervalued. You can may select 1 prospect drafted in the first round, 2 prospects drafted in the second or third round, and the rest on players drafted after the third round." />
              <InstructionBox title="Compete" text="Compete against other armchair GMs. Your players are evaluated based on how they perform in relation to where they were drafted. Your top 6 highest scoring players will determine your weekly scores." />
            </HStack>
            <Button bg="white" color="black" w="2xl" mb={10} onClick={handleEnterClick}>
              Enter Now
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
      <SignUpModal isOpen={isSignUpOpen} handleSuccessfulSignIn={handleSuccessfulSignIn} onClose={() => setIsSignUpOpen(false)} />
    </Modal>
  )
}