import { Select, Stack, Text } from "@chakra-ui/react"
import { School } from "../../../types"

interface Props {
  schools: School[]
  handleSchoolChange: (value: string) => void
}

export const SchoolsSelect = ({ schools, handleSchoolChange }: Props) => {
  return (
    <Stack w="100%">
      <Text fontWeight="bold">Schools</Text>
      <Select
        variant="filled"
        bg="white"
        color="brand.50"
        fontWeight="bold"
        onChange={(event) => handleSchoolChange(event.target.value)}
      >
        <option value="All">All</option>
        {
          schools.map(school => {
            return (
              <option key={school.name} value={school.name}>{ school.name }</option>            
            )
          })
        }
      </Select>
    </Stack>
  )
}