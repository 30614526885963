import {
  Modal,
  ModalOverlay,
  ModalContent,
} from "@chakra-ui/react"
import { SignUpPanel } from "./SignUpPanel"

interface Props {
  isOpen: boolean
  handleSuccessfulSignIn: () => void
  onClose: () => void
}

export const SignUpModal = ({ isOpen, handleSuccessfulSignIn, onClose }: Props) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg="transparent">
        <SignUpPanel onClose={handleSuccessfulSignIn}/>
      </ModalContent>
    </Modal>
  )
}