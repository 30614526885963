import {
  Button,
  chakra,
  useColorModeValue
} from "@chakra-ui/react"

export const PositiveButton = chakra((props) => {
  const textColor = useColorModeValue("white", "black")

  return (
    <Button
      {...props}
      bg="brand.500"
      color={textColor}
    />
  )
})