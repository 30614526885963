import { IoIosSearch } from "react-icons/io"
import {
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  useColorModeValue
} from "@chakra-ui/react"

interface Props {
  bgColor?: string
  placeHolder: string
  text: string
  width?: string
  handleTextChange: (value: string) => void
  handleIsFocused?: (value: boolean) => void
}

export const SearchBar = ({ bgColor, placeHolder, text, width, handleTextChange, handleIsFocused }: Props) => {
  const textColor = useColorModeValue("grey.500", "white")
  const color = useColorModeValue("light.100", "dark.100")
  const _bgColor = bgColor ?? color

  const handleBlur = () => {
    // Delay hiding the view to allow time for the click event on the link to trigger
    setTimeout(() => {
      handleIsFocused && handleIsFocused(false)
    }, 200)
  }

  return (
    <InputGroup w={ width ?? "100%" }>
      <Input
        variant="filled"
        fontWeight="bold"
        color="brand.50"
        bg={_bgColor}
        focusBorderColor="brand.50"
        placeholder={ placeHolder }
        _placeholder={{ color: textColor }}
        value={text}
        borderRadius={10}
        onFocus={() => handleIsFocused && handleIsFocused(true)}
        onBlur={handleBlur}
        onChange={(event) => handleTextChange(event.target.value)}
      />
      <InputRightElement
        pointerEvents='none'
        children={<Icon as={IoIosSearch} color={textColor} />}
      />
    </InputGroup>
  )
}