import {
  Stack,
  chakra,
  useColorModeValue
} from "@chakra-ui/react"

export const Section = chakra((props) => {
  const bg = useColorModeValue("light.100", "dark.100")
  
  return (
    <Stack
      {...props}
      borderRadius={10}
      bg={bg}
      boxShadow="md"
    />
  )
})