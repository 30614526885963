import { useNavigate } from "react-router-dom"
import {
  HStack,
  Spacer,
  Text,
  useColorModeValue,
  VStack, 
} from "@chakra-ui/react"
import { GradientButton, Section } from "../ui"

const InstructionBox = ({ width, title, text }: { width: string, title: string, text: string }) => {
  const bg = useColorModeValue("light.200", "dark.200")
  return (
    <Section
      w={width}
      minH={300}
      borderRadius={10}
      p={5}
    >
      <Text fontSize="xl" fontWeight="bold" pb={5}>
        { title }
      </Text>
      <Text fontSize="md">
        { text }
      </Text>
    </Section>
  )
}


export const NoProfileComponent = () => {
  const navigate = useNavigate()
  return (
    <VStack w="100%" h="100%">
      <Text pt={20} pb={5} pl={10} pr={10} fontSize="5xl" textAlign="center" fontWeight="extrabold">
        Create Your Big Board
      </Text>
      <HStack pt={5} spacing={5} pb={20}>
        <Spacer />
        <InstructionBox width="30%" title="Save your player rankings" text="Save your big boards to keep a record of how you rated the prospects for each NFL draft." />
        <InstructionBox width="30%" title="Keep track of your stats" text="See how accurate your big boards end up being when the players you rate start playing in the league." />
        <InstructionBox width="30%" title="Compete vs NFL GMs" text="Compare your ratings and stats to NFL GMs to see if you're better at evaluating talent than they are." />
        <Spacer />
      </HStack>
      <GradientButton onClick={() => navigate(`/players`)}>
        Get Started
      </GradientButton>
    </VStack>
  )
}