import { Button, chakra } from "@chakra-ui/react"

export const GradientButton = chakra((props) => {
  return (
    <Button
      {...props}
      bgGradient="linear-gradient(to right, #EB46A6, #E20FF5)"
      color="white"
    />
  )
})